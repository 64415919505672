import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RecordsGraph from './RecordsGraph';
import Statistics from './Statistics';
import MemberList from './MemberList';

const TeamAnalytics = () => {
  const { teamId } = useParams();
  const [members, setMembers] = useState([]);
  const [title, setTitle] = useState('Team Performance');

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/agency/organization/pods/${teamId}`);

      if (isSubscribed) {
        if (response.data.data?.name) {
          setTitle(`${response.data.data?.name} Performance`);
        }
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [teamId]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/users', {
        params: {
          pageSize: 1000,
          groupPodId: teamId,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'copyWritingTotalTaskCount',
            'copyWritingInitialTaskCount',
            'copyWritingRevisionTaskCount',
          ],
          permissionAccesses: [
            'writing.client_copy_writing_task_list.task.assignable',
            'writing.client_keyword_research_task_list.task.assignable',
            'writing.client_product_research_task_list.task.assignable',
          ],
        },
      });

      if (isSubscribed) {
        setMembers(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [teamId]);

  return (
    <div>
      <p className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
        {title}
      </p>

      <RecordsGraph
        reference={'created'}
        params={{ assigneesGroupPodId: teamId }}
      />

      <Statistics
        title="Team Overview"
        params={{ assigneesGroupPodId: teamId }}
      />

      <MemberList teamId={teamId} />
    </div>
  );
};

export default TeamAnalytics;
