import React, { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { dateFormatterUTC } from 'utils/formatters';
import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import PageHeader from 'components/PageHeader';
import KeywordForm from '../components/KeywordForm';
import SlideOver from 'components/SlideOver';
import { Table } from 'components';

const RestrictedKeywords = () => {
  let query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: parseInt(query.get('page') ?? 1),
    pageSize: parseInt(query.get('pageSize') ?? 10),
    sort: query.get('sort') ?? 'createdAt',
  });
  const [selected, setSelected] = useState({
    keyword: '',
    description: '',
  });
  const [open, setOpen] = useState(false);

  const getKeywords = async () => {
    setLoading(true);
    await axios
      .get(`/agency/clients/listing-mockup/keywords`, { params: params })
      .then((res) => {
        setKeywords(res.data.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    async function getData() {
      await getKeywords();
    }

    if (!loading) {
      getData();
    }
  }, [params]);

  const updateParams = (newParams, search = false) => {
    setParams(newParams);
    query.set('page', newParams.page);
    query.set('pageSize', newParams.pageSize);
    query.set('sort', newParams.sort);

    history.push(window.location.pathname + '?' + query.toString());
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = {
      ...params,
      page,
      pageSize: sizePerPage,
    };
    updateParams(newParams);
  };

  const onClickAdd = () => {
    setSelected({
      keyword: '',
      description: '',
    });
    setOpen(true);
  };

  const onClickEdit = (row) => {
    setSelected(row);
    setOpen(true);
  };

  const tableColumns = [
    {
      dataField: 'keyword',
      text: 'Keyword',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? dateFormatterUTC(cell) : ''}
          </span>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      formatter: (cell, row) => {
        return (
          <button onClick={() => onClickEdit(row)}>
            <PencilIcon className="m-1 h-5 w-5" />
          </button>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Restricted Keywords"
        containerClasses={''}
        left={
          <button
            onClick={() => onClickAdd()}
            className="flex items-center uppercase px-2 rounded-sm py-1 border border-gray-300 text-xs text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
          >
            <PlusIcon className="h-4 w-4" /> Add
          </button>
        }
      />

      <Table
        columns={tableColumns}
        data={keywords}
        onTableChange={onTableChange}
        params={params}
        keyField="listingMockupKeywordId"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
        loading={loading}
      />
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={selected.listingMockupKeywordId ? 'Update Record' : 'Add Record'}
        titleClasses="capitalize"
        size="3xl"
      >
        <div className="flow-root">
          <KeywordForm
            data={selected}
            action={selected.listingMockupKeywordId ? 'update' : 'add'}
            setOpen={setOpen}
            getKeywords={getKeywords}
          />
        </div>
      </SlideOver>
    </>
  );
};

export default RestrictedKeywords;
