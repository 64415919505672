import axios from 'axios';
import { keys } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

const Statistics = ({ title = 'Writing Team Overview', params = {} }) => {
  const [statistics, setStatistics] = useState({
    revision: {},
    overall: {},
    mainTask: {},
  });

  const [selectedMonth, setSelectedMonth] = useState(
    moment().startOf('month').format('YYYY-MM-DD')
  );

  let options = [];

  const ref = moment().startOf('month');

  while (ref.isSameOrAfter(moment().subtract(12, 'months').startOf('month'))) {
    options.push(ref.format('YYYY-MM-DD'));
    ref.subtract(1, 'month');
  }

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        `/agency/tasks/items/status/Revision/summary`,
        {
          params: {
            ...params,
            createdOnOrAfter: selectedMonth,
            createdOnOrBefore: moment(selectedMonth)
              .endOf('month')
              .format('YYYY-MM-DD'),
            attributes: ['count', 'averageClosingTime'],
            scopes: ['parentTasks'],
            taskListCodes: ['client_copy_writing_task_list'],
          },
        }
      );

      if (isSubscribed) {
        setStatistics(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [selectedMonth, params]);

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <p className="text-xl font-inter font-bold tracking-3/4 leading-1.2 text-grayscale-900">
          {title}
        </p>
        <div className="w-64 flex items-center">
          <p className="text-13 text-grayscale-800 leading-1.5 mr-4">Month</p>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="text-grayscale-900 text-mini py-3 w-full border-0 bg-white rounded-xl focus:outline-none focus:ring-0"
          >
            {options.map((option) => (
              <option className="px-5" value={option} key={option}>
                {moment(option).format('MMM YYYY')}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-3 gap-6">
        {[
          { key: 'overall', title: 'Overall Tasks' },
          { key: 'mainTask', title: 'Writing Tasks' },
          { key: 'revision', title: 'Revision Tasks' },
        ].map((card) => (
          <div
            className="rounded-3xl bg-white bs-shadow px-12 py-6"
            key={card.key}
          >
            <p className="text-grayscale-900 font-inter tracking-3/4 font-bold text-center">
              {card.title}
            </p>
            <div className="py-4 border-b border-grayscale-500">
              <p className="text-center text-13 text-grayscale-700 leading-1.5">
                Total Tasks
              </p>
              <p className="text-center text-32 tracking-1 text-grayscale-800 leading-1.2 font-inter">
                {statistics[card.key]['count'] || 0}
              </p>
            </div>
            <div className="py-4">
              <p className="text-center text-13 text-grayscale-700 leading-1.5">
                Average Lead Time
              </p>
              <p className="text-center text-32 tracking-1 text-grayscale-800 leading-1.2 font-inter">
                {statistics[card.key]['averageClosingTime'] || 0}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
