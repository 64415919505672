import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/solid';

const MemberList = ({ teamId }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/users', {
        params: {
          pageSize: 1000,
          groupPodId: teamId,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'designTotalTaskCount',
            'designInitialTaskCount',
            'designRevisionTaskCount',
          ],
          permissionAccesses: [
            'design.client_design_task_list.task.assignable',
          ],
        },
      });

      if (isSubscribed) {
        setMembers(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [teamId]);

  return (
    <div className="rounded-3xl bg-white bs-shadow mt-8">
      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-grayscale-200">
            <tr>
              <td className="text-grayscale-600 text-13 leading-1.5 pl-8 pr-2 py-5">
                Team
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Overall Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Design Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Revision Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-center">
                Action
              </td>
            </tr>
          </thead>

          <tbody>
            {members.map((member) => (
              <tr key={member.userId}>
                <td className="text-grayscale-800 leading-1.5 pl-8 pr-2 py-3">
                  {member.firstName} {member.lastName}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {member.designTotalTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {member.designInitialTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {member.designRevisionTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-center flex justify-center">
                  <NavLink to={`/design/analytics/assignee/${member.userId}`}>
                    <UserCircleIcon className="w-6 h-6 text-secondary" />
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberList;
