import axios from 'axios';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { PlusIcon } from '@heroicons/react/outline';

import TaskRow from 'features/tasks/components/TaskRow';
import NewTask from 'features/tasks/components/NewTask';
import TaskRowKanban from 'features/tasks/components/TaskRowKanban';
import NewTaskKanban from 'features/tasks/components/NewTaskKanban';

import classNames from 'utils/classNames';
import { strSpaceToDash } from 'utils/formatters';

const TaskGroup = ({
  data = [],
  groupName,
  tabText,
  onChange,
  groupBy,
  allowAddTask = true,
  assigneeOptions = [],
  allowedStatuses = [],
  permissions = {},
  canDrag = false,
}) => {
  const { boardView } = useSelector((state) => state.writing);
  const [addTask, setAddTask] = useState(false);
  const onAddNewTask = (task) => {
    setAddTask(false);
    onChange();
  };
  const [{ isActive }, drop] = useDrop({
    accept: 'card',
    collect: (monitor) => ({
      isActive:
        monitor.canDrop() &&
        monitor.isOver() &&
        (groupBy == 'status' || groupBy == 'priority'),
    }),
    drop: async (item) => {
      if (groupBy === 'status') {
        await axios.put(`/agency/tasks/items/${item.data.taskItemId}`, {
          status: groupName,
        });
      } else {
        let newPriority = item.data.priority;
        switch (groupName) {
          case 'No priority':
            newPriority = null;
            break;
          case 'low':
            newPriority = 'low';
            break;
          case 'normal':
            newPriority = 'normal';
            break;
          case 'high':
            newPriority = 'high';
            break;
        }

        await axios.put(`/agency/tasks/items/${item.data.taskItemId}`, {
          priority: newPriority,
        });
      }
      onChange();
    },
  });

  return (
    <div className="list-group">
      <div
        className={classNames(
          'list-group-column',
          strSpaceToDash(groupName.toLowerCase()),
          isActive ? 'border-2 shadow-xl drop-active' : '',
          'flex flex-col h-full'
        )}
        ref={drop}
      >
        <div className="list-group-header">
          <div className="col-span-4 flex sticky left-0 kanban-col-span-12">
            <span
              className={classNames(
                strSpaceToDash(groupName.toLowerCase()),
                'text-white',
                'list-group-tab',
                groupBy
              )}
              data-count={data.length}
            >
              {tabText}
            </span>
          </div>
          <div className="list-group-th col-span-2">Assignee</div>
          <div className="list-group-th col-span-1">Due Date</div>
          <div className="list-group-th col-span-1">Priority</div>
          <div className="list-group-th col-span-1">Brand Strategist</div>
          <div className="list-group-th col-span-1">Account Specialist</div>
          <div className="list-group-th col-span-2">Time Tracker</div>
        </div>

        {data &&
          isArray(data) &&
          data.map((row) =>
            boardView === 'kanban' ? (
              <TaskRowKanban
                key={row.taskItemId}
                data={row}
                onChange={onChange}
                space={row.list.folder.space}
                folder="writing"
                assigneeOptions={assigneeOptions}
                permissions={permissions}
              />
            ) : (
              <TaskRow
                key={row.taskItemId}
                data={row}
                onChange={onChange}
                space={row.list.folder.space}
                showClient={true}
                showManagers={true}
                assigneeOptions={assigneeOptions}
                allowedStatuses={allowedStatuses}
                defaultStatus="Open"
                notifyAssignee={true}
                permissions={permissions}
                canDrag={canDrag}
              />
            )
          )}

        {isActive && (
          <div className="flex items-center justify-center p-2">
            <div className="font-inter border-4 border-dashed border-gray-200 w-full py-6 text-center text-gray-500 text-sm">
              Drop here
            </div>
          </div>
        )}
        {allowAddTask ? (
          addTask ? (
            boardView === 'kanban' ? (
              <NewTaskKanban
                taskListId={null}
                cancel={() => setAddTask(false)}
                created={onAddNewTask}
                folder="writing"
                assigneeOptions={assigneeOptions}
              />
            ) : (
              <NewTask
                taskListId={null}
                cancel={() => setAddTask(false)}
                created={onAddNewTask}
                folder="writing"
                status="Open"
                assigneeOptions={assigneeOptions}
              />
            )
          ) : (
            <div
              className={classNames(
                boardView === 'kanban' ? 'border-t' : '',
                'py-3 flex'
              )}
            >
              <div className="sticky left-0 flex items-center px-6">
                <button
                  onClick={() => setAddTask(true)}
                  className="text-gray-500 hover:text-gray-700 font-bold tracking-widest text-sm flex items-center"
                >
                  <PlusIcon className="w-5 h-5 inline mr-1" /> New Task
                </button>
              </div>
              <div>&nbsp;</div>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TaskGroup;
