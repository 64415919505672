import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllRolesAsync = createAsyncThunk(
  'roles/fetchAll',
  async (params) => {
    const response = await axios.get('/v2/roles', { params });

    return response.data.data;
  }
);

export const fetchRoleListAsync = createAsyncThunk(
  'roles/fetchList',
  async (params) => {
    const response = await axios.get('/v2/roles/list', { params });

    return response.data.data;
  }
);

export const fetchRoleDetailsAsync = createAsyncThunk(
  'roles/fetchDetails',
  async (roleId) => {
    const response = await axios.get(`/v2/roles/${roleId}`);

    return response.data.data;
  }
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    all: { loading: true, data: [] },
    list: { loading: true, data: { rows: [], count: 0 } },
    selected: { loading: true, data: {} },
  },
  reducers: {
    setRoles: (state, action) => {
      state.all = action.payload;
    },
    setRoleList: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [fetchAllRolesAsync.fulfilled]: (state, { payload }) => {
      state.all = { loading: false, data: payload };
    },
    [fetchRoleListAsync.fulfilled]: (state, { payload }) => {
      state.list = { loading: false, data: payload };
    },
    [fetchRoleDetailsAsync.pending]: (state) => {
      state.selected = { loading: true, data: {} };
    },
    [fetchRoleDetailsAsync.fulfilled]: (state, { payload }) => {
      state.selected = { loading: false, data: payload };
    },
  },
});

export const { setRoles, setRoleList } = rolesSlice.actions;

export default rolesSlice.reducer;
