import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchTeamDetails } from '../designSlice';

import TaskSummary from '../components/TaskSummary';

const TeamSummary = () => {
  const { podId } = useParams();
  const dispatch = useDispatch();

  const { team } = useSelector((state) => state.design);

  useEffect(() => {
    dispatch(fetchTeamDetails(podId));
  }, [podId]);

  return parseInt(team.podId) === parseInt(podId) ? (
    <div>
      <p className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
        My Team’s Workspace
      </p>

      <TaskSummary code={'client_design_task_list'} podId={podId} />
    </div>
  ) : (
    ''
  );
};

export default TeamSummary;
