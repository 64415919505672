import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchUsers } from '../writingSlice';

const TaskDetails = ({ code }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.writing);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 1000,
        sort: 'firstName:asc',
        permissionAccesses: [`writing.${code}.task.assignable`],
      })
    );
  }, [code]);

  return (
    <TaskDetailsPage
      assigneeOptions={users.rows}
      notifyAssignee={true}
      serviceAsinData={{}}
      onCloseModal={() => history.push('/writing/analytics')}
    />
  );
};

export default TaskDetails;
