import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAgencyClients = createAsyncThunk(
  'agencyClients/fetchClients',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients`, { params });
    thunkAPI.dispatch(setPaginationParams(params));
    return response.data.data;
  }
);

export const fetchActiveClients = createAsyncThunk(
  'agencyClients/fetchActiveClients',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/active`, { params });
    thunkAPI.dispatch(setActivePaginationParams(params));
    return response.data.data;
  }
);

export const fetchForOnboardingClients = createAsyncThunk(
  'agencyClients/fetchForOnboardingClients',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/foronboarding`, {
      params,
    });
    thunkAPI.dispatch(setForonboardingPaginationParams(params));
    return response.data.data;
  }
);

export const fetchInactiveClients = createAsyncThunk(
  'agencyClients/fetchInactiveClients',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/inactive`, { params });
    thunkAPI.dispatch(setInactivePaginationParams(params));
    return response.data.data;
  }
);

export const fetchClientsList = createAsyncThunk(
  'agencyClients/fetchClientsList',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/list`, { params });
    return response.data.data;
  }
);

export const agencyClientsSlice = createSlice({
  name: 'agencyClients',
  initialState: {
    agencyClients: [],
    active: [],
    forOnboarding: [],
    inactive: [],
    list: [],
    paginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'client:asc',
      status: '',
      type: '',
    },
    initialParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'client:asc',
      status: '',
    },
    activePaginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'client:asc',
      status: '',
      service: '',
    },
    foronboardingPaginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'client:asc',
      status: '',
      service: '',
    },
    inactivePaginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'client:asc',
      status: '',
      service: '',
    },
  },
  reducers: {
    setPaginationParams: (state, action) => {
      state.paginationParams = action.payload;
    },
    setAgencyClients: (state, action) => {
      state.agencyClients = action.payload;
    },
    setActivePaginationParams: (state, action) => {
      state.activePaginationParams = action.payload;
    },
    setForonboardingPaginationParams: (state, action) => {
      state.foronboardingPaginationParams = action.payload;
    },
    setForonboarding: (state, action) => {
      state.forOnboarding = action.payload;
    },
    setInactivePaginationParams: (state, action) => {
      state.inactivePaginationParams = action.payload;
    },
  },
  extraReducers: {
    [fetchAgencyClients.fulfilled]: (state, { payload }) => {
      state.agencyClients = payload;
    },
    [fetchActiveClients.fulfilled]: (state, { payload }) => {
      state.active = payload;
    },
    [fetchForOnboardingClients.fulfilled]: (state, { payload }) => {
      state.forOnboarding = payload;
    },
    [fetchInactiveClients.fulfilled]: (state, { payload }) => {
      state.inactive = payload;
    },
    [fetchClientsList.fulfilled]: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export const {
  setPaginationParams,
  setAgencyClients,
  setActivePaginationParams,
  setInactivePaginationParams,
  setForonboardingPaginationParams,
  setForonboarding,
} = agencyClientsSlice.actions;

export default agencyClientsSlice.reducer;
