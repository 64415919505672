import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import useBodyClass from 'hooks/useBodyClass';

import { fetchTaskItems, fetchUsers } from '../designSlice';

import TaskGroup from './TaskGroup';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';

const TaskWorkspace = ({ code, podId, params }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const [formattedItems, setFormattedItems] = useState({});

  const { taskListStatuses, items, users, boardView } = useSelector(
    (state) => state.design
  );

  useBodyClass(['tasks-light', 'tasks', boardView]);

  useEffect(() => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        assigneesGroupPodId: podId,
        scopes: ['owned'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  }, [code, params, podId]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 1000,
        groupPodId: podId,
        sort: 'firstName:asc',
        permissionAccesses: [`design.${code}.task.assignable`],
      })
    );
  }, [code, podId]);

  useEffect(() => {
    let formatted = { ...items };

    if (
      boardView === 'kanban' &&
      (params.groupBy === 'status' || params.groupBy === 'priority')
    ) {
      let newFormatted = {};

      const columns = {
        priority: ['No priority', 'low', 'normal', 'high'],
        status: taskListStatuses.map((i) => i.name),
      };

      columns[params.groupBy].forEach((key) => {
        newFormatted[key] = formatted[key] ?? [];
      });

      formatted = newFormatted;
    }

    setFormattedItems(formatted);
  }, [items, params.groupBy, boardView]);

  const refreshList = () => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        assigneesGroupPodId: podId,
        scopes: ['owned'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  };

  return (
    <div className="w-800 xl:w-full list-container flex-1">
      {!isArray(formattedItems) &&
        Object.keys(formattedItems).map((groupKey) => {
          return (
            <TaskGroup
              key={groupKey}
              groupName={groupKey}
              tabText={groupKey}
              data={formattedItems[groupKey]}
              onChange={refreshList}
              status={groupKey}
              groupBy={params.groupBy}
              allowAddTask={false}
              assigneeOptions={users.rows}
            />
          );
        })}

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={refreshList}
                assigneeOptions={users.rows}
                notifyAssignee={true}
                serviceAsinData={{
                  name: 'service-asin',
                  type: 'service-asin',
                  title: 'Service ASIN',
                  checklists: [
                    {
                      name: 'Design work needed',
                      description: 'Design work needed',
                      items: [
                        { value: 'Listing Images' },
                        { value: 'A+ Copy' },
                        { value: 'Storefront' },
                        { value: 'Listing Video' },
                      ],
                    },
                  ],
                }}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default TaskWorkspace;
