import axios from 'axios';
import { useEffect, useState } from 'react';
import { UserGroupIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';

const TeamList = () => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/agency/organization/pods`, {
        params: {
          type: 'design',
          attributes: [
            'podId',
            'name',
            'designInitialTaskCount',
            'designRevisionTaskCount',
            'designTotalTaskCount',
          ],
        },
      });

      if (isSubscribed) {
        setTeams(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  return (
    <div className="rounded-3xl bg-white bs-shadow mt-8">
      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-grayscale-200">
            <tr>
              <td className="text-grayscale-600 text-13 leading-1.5 pl-8 pr-2 py-5">
                Team
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Overall Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Design Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-right">
                Revision Tasks
              </td>
              <td className="text-grayscale-600 text-13 leading-1.5 px-2 py-5 text-center">
                Action
              </td>
            </tr>
          </thead>

          <tbody>
            {teams.map((team) => (
              <tr key={team.podId}>
                <td className="text-grayscale-800 leading-1.5 pl-8 pr-2 py-3">
                  {team.name}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {team.designTotalTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {team.designInitialTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-right">
                  {team.designRevisionTaskCount}
                </td>
                <td className="text-grayscale-800 leading-1.5 px-2 py-3 font-bold text-center flex justify-center">
                  <NavLink to={`/design/analytics/teams/${team.podId}`}>
                    <UserGroupIcon className="w-6 h-6 text-secondary" />
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamList;
