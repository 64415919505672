import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUserListAsync = createAsyncThunk(
  'users/getList',
  async (params) => {
    const response = await axios.get(`/v2/users/list`, { params });

    return response.data.data;
  }
);

export const fetchAllUsersAsync = createAsyncThunk(
  'users/fetchAll',
  async (params) => {
    const response = await axios.get('/v2/users', { params });

    return response.data.data;
  }
);

export const fetchUserDetailsAsync = createAsyncThunk(
  'users/fetchDetails',
  async ({ userId, params = {} }) => {
    const response = await axios.get(`/v2/users/${userId}`, { params });

    return response.data.data;
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    all: { loading: true, data: [] },
    list: { loading: true, data: { rows: [] } },
    details: { loading: true, data: {} },
  },
  reducers: {
    setUsers: (state, action) => {
      state.all = action.payload;
    },
    setUserList: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [fetchUserListAsync.pending]: (state) => {
      state.list.loading = true;
    },
    [fetchUserListAsync.fulfilled]: (state, { payload }) => {
      state.list = { loading: false, data: payload };
    },
    [fetchAllUsersAsync.pending]: (state) => {
      state.all = { loading: true, data: [] };
    },
    [fetchAllUsersAsync.fulfilled]: (state, { payload }) => {
      state.all = { loading: false, data: payload };
    },
    [fetchUserDetailsAsync.pending]: (state) => {
      state.details = { loading: true, data: {} };
    },
    [fetchUserDetailsAsync.fulfilled]: (state, { payload }) => {
      state.details = { loading: false, data: payload };
    },
  },
});

export const { setUsers, setUserList } = usersSlice.actions;

export default usersSlice.reducer;
