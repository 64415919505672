import { useFormikContext } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { Field, ErrorMessage } from 'formik';
import InputAppend from 'components/Forms/InputAppend';
import {
  ACCOUNT_CREATION,
  ACCOUNT_HEALTH_ISSUE,
  ACCOUNT_HEALTH_MANAGEMENT,
  ACH,
  ADVERTISING_MANAGEMENT,
  CODE_PREFIX,
  META_ADVERTISING,
  FREE_SERVICE,
  FULL_ACCOUNT_MANAGEMENT,
  GOOGLE_ADVERTISING,
  LISTING_CONTENT_CREATION,
  LUMPSUM,
  MAILCHIMP_MANAGEMENT,
  ONE_TIME,
  PLAN_CODE_PREFIXES,
  PPC_MANAGEMENT,
  PPC_AUDIT_AND_PROPOSAL,
  RECURRING,
  SELLER_LAUNCH,
  SEO_MANAGEMENT,
  WEBSITE_ACTIVATION,
  WEBSITE_CONTENT,
  WIRE_TRANSFER,
  PAID_REVIEW_PROGRAM,
} from 'utils/constants';
import { useState, useEffect, useMemo } from 'react';
import {
  AGENCY_CLIENT_SERVICES,
  AGENCY_CLIENT_SERVICES_DESCRIPTION,
  AGENCY_CLIENT_PLAN_SERVICES,
} from 'utils/constants';
import useBilling from 'hooks/useBilling';
import axios from 'axios';
import { useSelector } from 'react-redux';

const SubscriptionFormPartial = ({ action }) => {
  const { client } = useSelector((state) => state.sales);
  const { plans, marketplaces, plansGrouped } = useBilling();
  const { setFieldValue, handleChange, values } = useFormikContext();
  const [approvers, setApprovers] = useState(undefined);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState([
    RECURRING,
  ]);
  const [availableServices, setAvailableServices] = useState([]);

  useEffect(async () => {
    await axios
      .get(`/agency/sales/clients/reps?roles=Sales Administrator,Sales Admin`)
      .then((res) => {
        setApprovers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (action === 'edit' && values.convert_retainer_cycle) {
      setFieldValue('convertDisabled', true);
    }
  }, [action, values.convert_retainer_cycle]);

  const onUpdatePlan = (plan, paymentOption) => {
    if (paymentOption === ONE_TIME) {
      setFieldValue('billing_cycles', 1);
      setFieldValue('noExpiry', false);
    }
    setFieldValue('plan_code', plan.planCode);
    setFieldValue(
      'price',
      paymentOption == FREE_SERVICE ? 0 : plan.recurringPrice
    );
  };

  const onPlanChange = (value, setFieldValue) => {
    if (value !== '') {
      const codePrefix = PLAN_CODE_PREFIXES[value];

      if (codePrefix) {
        setAvailableServices(AGENCY_CLIENT_PLAN_SERVICES[codePrefix]);
        setFieldValue('plan_code', '');
        setFieldValue('plan_description', '');
        setFieldValue('price', '');
      } else {
        const plan = plans.find((p) => p.name === value);
        setAvailableServices(AGENCY_CLIENT_SERVICES);
        setFieldValue('plan_code', plan.planCode);
        setFieldValue('plan_description', plan.description);
        setFieldValue('price', plan.recurringPrice);
      }
      setFieldValue('name', value);
      setFieldValue('currency_code', 'USD');
      setFieldValue('pricebook_id', '');
      setFieldValue('service', '');
      setFieldValue('paymentOption', '');
      setFieldValue('interval', '');
      setFieldValue('billing_cycles', '');
      setFieldValue('noExpiry', true);
      setAvailablePaymentOptions([]);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onChangeService = (e) => {
    const service = e.target.value;
    const codePrefix = PLAN_CODE_PREFIXES[values.name];
    setFieldValue('paymentOption', '');
    setFieldValue('interval', '');

    if (codePrefix) {
      switch (service) {
        case FULL_ACCOUNT_MANAGEMENT:
        case PPC_MANAGEMENT:
        case ACCOUNT_HEALTH_MANAGEMENT:
        case ADVERTISING_MANAGEMENT:
        case GOOGLE_ADVERTISING:
        case META_ADVERTISING:
        case SEO_MANAGEMENT:
        case WEBSITE_CONTENT:
        case MAILCHIMP_MANAGEMENT:
        case WEBSITE_ACTIVATION:
          setAvailablePaymentOptions([RECURRING, LUMPSUM]);
          break;
        case LISTING_CONTENT_CREATION:
          switch (codePrefix) {
            case CODE_PREFIX.AMAZON_MANAGEMENT:
              setAvailablePaymentOptions([
                ONE_TIME,
                WIRE_TRANSFER,
                ACH,
                FREE_SERVICE,
              ]);
              break;
            case CODE_PREFIX.WALMART_MANAGEMENT:
              setAvailablePaymentOptions([ONE_TIME]);
              break;
          }
          break;
        case ACCOUNT_HEALTH_ISSUE:
        case SELLER_LAUNCH:
        case ACCOUNT_CREATION:
        case PPC_AUDIT_AND_PROPOSAL:
        case PAID_REVIEW_PROGRAM:
          setAvailablePaymentOptions([ONE_TIME]);
          break;
        default:
          setAvailablePaymentOptions([RECURRING]);
      }
    } else {
      setAvailablePaymentOptions([RECURRING]);
    }

    setFieldValue(
      'plan_description',
      codePrefix === CODE_PREFIX.AMAZON_MANAGEMENT &&
        service in AGENCY_CLIENT_SERVICES_DESCRIPTION
        ? AGENCY_CLIENT_SERVICES_DESCRIPTION[service]
        : ''
    );

    handleChange(e);
  };

  const onChangePaymentOption = (e) => {
    const paymentOption = e.target.value;
    if (paymentOption !== LUMPSUM) {
      setFieldValue('interval', '');
    }
    if (
      paymentOption === RECURRING ||
      paymentOption === ONE_TIME ||
      paymentOption == FREE_SERVICE ||
      paymentOption == WIRE_TRANSFER ||
      paymentOption == ACH
    ) {
      const codePrefix = PLAN_CODE_PREFIXES[values.name];
      if (codePrefix) {
        const selectedPlan = plans.find(
          (p) =>
            p.name === values.name &&
            p.billingCycles === -1 &&
            !p.planCode.includes('LUMPSUM')
        );
        onUpdatePlan(selectedPlan, paymentOption);
      }
    }
    handleChange(e);
  };

  const onUpdateInterval = (e) => {
    const interval = e.target.value.split('-');
    const selectedPlan = plans.find(
      (p) =>
        p.name === values.name &&
        p.interval === parseInt(interval[0]) &&
        p.intervalUnit === interval[1]
    );
    onUpdatePlan(selectedPlan, 'lumpsum');
    handleChange(e);
  };

  const setManagedAsin = (val, setFieldValue) => {
    let numVal = Number(val);
    let arr = Array(numVal)
      .fill()
      .map(() => {
        return { asin: '' };
      });
    setFieldValue('managedAsins', arr);
  };

  const onUpdateStartDate = (e) => {
    setFieldValue('starts_at', e.target.value);
    handleChange(e);
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);
    const base64Images = await Promise.all(files.map((file) => toBase64(file)));
    setFieldValue('images', [...values.images, ...base64Images]);
  };

  const clientName = useMemo(() => {
    return client && client.lead && client.lead.companyName
      ? client.lead.companyName
      : '';
  }, [client]);

  return (
    <div className="sm:grid grid-cols-2 p-10 gap-4 text-left">
      <div className="col-span-2 flex flex-col space-y-2 border-b pb-8 text-left">
        <Label className="text-left text-grayscale-600">Partner's Name</Label>
        <span>{clientName}</span>
      </div>
      <div className="col-span-1 flex flex-col">
        <Label>
          Plan <RequiredAsterisk />
        </Label>
        <Field
          name="name"
          as="select"
          className="form-select disabled-white"
          onChange={(e) => onPlanChange(e.target.value, setFieldValue)}
        >
          <option value="">Select Plan</option>
          {plansGrouped &&
            plansGrouped.map((plan) => (
              <option key={plan} value={plan}>
                {plan}
              </option>
            ))}
        </Field>
        <ErrorMessage
          name="name"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <Label htmlFor="service">Service Type</Label>
        <Field
          name="service"
          as="select"
          className="form-select disabled-white"
          onChange={onChangeService}
        >
          <option value="">Select Service</option>
          {availableServices.map((service) => (
            <option key={service} value={service}>
              {service}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name="service"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <Label>Default Marketplace</Label>
        <Field
          name="defaultMarketplace"
          as="select"
          className="form-select disabled-white"
        >
          {marketplaces &&
            marketplaces.map((marketplace) => (
              <option
                key={marketplace.marketplaceId}
                value={marketplace.marketplaceId}
              >
                {marketplace.countryCode} - {marketplace.name}
              </option>
            ))}
        </Field>
        <ErrorMessage
          name="defaultMarketplace"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>{' '}
      <div className="col-span-1 flex flex-col">
        <Label htmlFor="paymentOptions">Payment Options</Label>
        <Field
          name="paymentOption"
          as="select"
          className="form-select disabled-white"
          onChange={onChangePaymentOption}
        >
          <option value="">Select Payment Option</option>
          {availablePaymentOptions.map((paymentOption) => (
            <option key={paymentOption} value={paymentOption}>
              {paymentOption}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name="paymentOption"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
      {values.paymentOption !== FREE_SERVICE && (
        <div className="col-span-1 flex flex-col">
          <Label
            classes="text-ellipsis w-full overflow-hidden whitespace-nowrap text-left"
            style={{ textOverflow: 'ellipsis' }}
          >
            {values.paymentOption === RECURRING
              ? 'Monthly Retainer'
              : 'Service Fee'}
            <RequiredAsterisk />
          </Label>
          <div className="relative rounded-md shadow-sm w-full">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <Field
              name="price"
              placeholder=""
              className="form-input disabled-white pl-7 r-12"
              type="number"
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <Label htmlFor="currency_code" classes="sr-only">
                Currency
              </Label>
              <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                {values.currency_code}
              </span>
            </div>
          </div>
          <ErrorMessage
            name="price"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      )}
      {values.paymentOption === LUMPSUM && (
        <div className="col-span-1 flex flex-col">
          <Label>Interval</Label>
          <Field
            name="interval"
            as="select"
            className="form-select disabled-white"
            onChange={onUpdateInterval}
          >
            <option value="" disabled>
              Select Lumpsum Interval
            </option>
            <option value="3-months">3 months</option>
            <option value="6-months">6 months</option>
            <option value="9-months">9 months</option>
            <option value="1-years">1 year</option>
            <option value="15-months">15 months</option>
            <option value="18-months">18 months</option>
            <option value="2-years">2 years</option>
          </Field>
          <ErrorMessage
            name="interval"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      )}
      {values.name !== 'Website & Social Media Management' && (
        <>
          <div className="col-span-1 flex flex-col">
            <Label>
              {values.name?.includes('Walmart')
                ? 'No. of Item IDs'
                : 'No. of ASINs'}
            </Label>
            <Field
              name="noOfAsins"
              placeholder={
                values.name?.includes('Walmart')
                  ? 'No. of Item IDs'
                  : 'No. of ASINs'
              }
              className="form-input disabled-white"
              onChange={(e) => {
                handleChange(e);
                setManagedAsin(e.target.value, setFieldValue);
              }}
              type="number"
            />
            <ErrorMessage
              name="noOfAsins"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
        </>
      )}
      <div className="col-span-1 flex flex-col">
        <Label>Contract Start Date</Label>
        <Field
          name="contractSigned"
          className="form-input disabled-white"
          type="date"
          onChange={onUpdateStartDate}
        />
        <ErrorMessage
          name="contractSigned"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
      {(values.paymentOption === LUMPSUM ||
        values.paymentOption === RECURRING ||
        values.paymentOption === WIRE_TRANSFER ||
        values.paymentOption === ACH) && (
        <div className="col-span-1 flex flex-col">
          <div className="col-span-6 sm:col-span-6">
            <Label htmlFor="billing_cycles">
              Expires After {!values.noExpiry && <RequiredAsterisk />}
            </Label>
            <div className="flex flex-col">
              <Field name="billing_cycles">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <InputAppend
                      type="number"
                      name="billing_cycles"
                      appendText="cycles"
                      border="border-white"
                      classes="bg-gray-50 disabled-gray py-2 pl-3"
                      containerClasses="w-1/2 sm:w-auto"
                      required={!values.noExpiry}
                      disabled={values.noExpiry}
                      {...field}
                    />
                  </>
                )}
              </Field>
              <div className="flex items-center mt-1 ml-1">
                <Field
                  type="checkbox"
                  name="noExpiry"
                  className="form-checkbox text-blue-700 focus:border-blue-700 focus:ring-blue-700 rounded"
                />
                <Label htmlFor="noExpiry" classes="ml-2 text-sm">
                  Never Expires
                </Label>
              </div>
            </div>
            <ErrorMessage
              name="billing_cycles"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
        </div>
      )}
      {values.paymentOption === FREE_SERVICE && (
        <>
          <div className="col-span-1 flex flex-col">
            <Label>Contract End Date</Label>
            <Field
              name="contractEndDate"
              className="form-input disabled-white"
              type="date"
            />
            <ErrorMessage
              name="contractEndDate"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>

          <div className="col-span-1 flex flex-col">
            <Label htmlFor="paymentOptions">Approver</Label>
            <Field
              name="approvedBy"
              as="select"
              className="form-select disabled-white"
            >
              <option value="">Select Approver</option>
              {approvers &&
                approvers.map((s) => {
                  return (
                    <option key={s} value={s.email}>
                      {`${s.firstName} ${s.lastName}`}
                    </option>
                  );
                })}
            </Field>
            <ErrorMessage
              name="approvedBy"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          <div className="col-span-2 flex flex-col ">
            <Label>Approval Screenshots</Label>

            <label className="relative inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>Upload one or more images</span>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer rounded-full"
              />
            </label>
            <div className="mt-4 flex justify-start">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {values.images.map((image, index) => (
                  <div key={index} className="p-2">
                    <img
                      src={image}
                      alt="Selected Image"
                      className="w-full h-auto"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-2 flex flex-col">
            <Label>Why is it Free of service?</Label>
            <Field
              name="freeServiceReason"
              placeholder="Description"
              className="form-input disabled-white"
              as="textarea"
            />
            <ErrorMessage
              name="freeServiceReason"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
        </>
      )}
      <div className="col-span-2 flex flex-col items-start space-y-2 border-t mt-4 pt-4">
        <Label>Proposal Description</Label>
        <Field
          name="plan_description"
          placeholder="Description"
          className="form-input disabled-white"
          as="textarea"
          rows={5}
        />
        <ErrorMessage
          name="plan_description"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
    </div>
  );
};
export default SubscriptionFormPartial;
