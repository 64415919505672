import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTaskListClients = createAsyncThunk(
  'design/taskListClients',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/tasks/lists`, { params });

    return {
      ...response.data.data,
      rows: response.data.data.rows.map((i) => {
        return {
          taskListId: i.taskListId,
          taskFolderId: i.taskFolderId,
          code: i.code,
          name: i.name,
          taskSpaceId: i.folder.taskSpaceId,
          clientId: i.folder.space.client.agencyClientId,
          client: i.folder.space.client.client,
        };
      }),
    };
  }
);

export const fetchUsers = createAsyncThunk(
  'design/users',
  async (params, thunkAPI) => {
    const response = await axios.get(`/users`, { params });

    return response.data.data;
  }
);

export const fetchTaskItems = createAsyncThunk(
  'design/taskItems',
  async (params, thunkAPI) => {
    let payload = { ...params };
    delete payload.page;
    delete payload.pageSize;

    const response = await axios.get(`/agency/tasks/items`, {
      params: payload,
    });

    return response.data.data;
  }
);

export const fetchTeamDetails = createAsyncThunk(
  'design/podDetails',
  async (podId, thunkAPI) => {
    const response = await axios.get(`/agency/organization/pods/${podId}`);

    return response.data.data;
  }
);

export const designSlice = createSlice({
  name: 'design',
  initialState: {
    list: { rows: [] },
    items: {},
    boardView: 'list',
    users: { rows: [] },
    team: { taskListCodes: [] },
    taskListStatuses: [
      { order: 1, type: 'open', name: 'Open', color: '#1879D8' },
      { order: 2, type: 'open', name: 'To Do', color: '#1879D8' },
      { order: 3, type: 'open', name: 'In Progress', color: '#1879D8' },
      {
        order: 4,
        type: 'open',
        name: 'For Initial Editing',
        color: '#1879D8',
      },
      { order: 5, type: 'open', name: 'For Final Editing', color: '#1879D8' },
      { order: 6, type: 'open', name: 'Revision', color: '#1879D8' },
      { order: 7, type: 'open', name: 'Submitted', color: '#13e9b0' },
      { order: 8, type: 'closed', name: 'Complete', color: '#1879D8' },
      { order: 9, type: 'open', name: 'On Hold or Cancel', color: '#1879D8' },
      { order: 10, type: 'open', name: 'Incomplete', color: '#1879D8' },
    ],
  },
  reducers: {
    setBoardView: (state, action) => {
      state.boardView = action.payload;
    },
  },
  extraReducers: {
    [fetchTaskListClients.fulfilled]: (state, { payload }) => {
      state.list = payload;
    },
    [fetchTaskItems.fulfilled]: (state, { payload }) => {
      state.items = payload;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
    },
    [fetchTeamDetails.fulfilled]: (state, { payload }) => {
      state.team = payload;
    },
  },
});

export const { setBoardView } = designSlice.actions;

export default designSlice.reducer;
