import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Table } from 'components';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { setAlert } from 'features/alerts/alertsSlice';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';
import DatePicker from 'features/datePicker/DatePicker';
import moment from 'moment';
import { ConfirmationModal } from 'components';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import PageHeader from 'components/PageHeader';
import TabNav from 'components/TabNav';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import {
  PlusIcon,
  UserCircleIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/outline';
import { setLeadsPaginationParams } from './leadsSlice';
import { floatFormatter, ago } from 'utils/formatters';
import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';
import Label from 'components/Forms/Label';
import ColumnPicker from './components/ColumnPicker';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { setParams } from 'features/advertising/optimizations/optimizationSlice';
import MultipleFilter from 'components/MultipleFilter';
import Button from 'components/Button';
import Badge from 'components/Badge';
import { debounce } from 'lodash';
import { PRE_PITCH_STATUSES } from './utils/constants';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import ChangeStatusModal from './components/ChangeStatusModal';

const Overview = ({ tabs, page }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userCan, isAgencySuperUser } = usePermissions();
  const history = useHistory();
  const [leads, setLeads] = useState({});
  const { leadsPaginationParams } = useSelector((state) => state.leads);
  const { params, updateParams, sortParam } = useQueryParams({
    ...leadsPaginationParams,
  });
  const me = useSelector(selectAuthenticatedUser);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenLemlist, setIsOpenLemlist] = useState(false);
  const [assignToMe, setAssignToMe] = useState([]);
  const selectedDates = useSelector(selectCurrentDateRange);
  const [isSearching, setSearching] = useState(false);
  const [isChangeStatusFormOpen, setIsChangeStatusFormOpen] = useState(false);
  const [isSkipGet, setSkipGet] = useState(true); // skip initial load

  const [navTabs, setNavTabs] = useState(
    page === 'Data'
      ? [
          {
            name: 'All Leads',
            href: '#',
            current: true,
            visible: userCan('leads.list'),
          },
        ]
      : page === 'No Seller Info'
      ? [
          {
            name: 'No Seller Info',
            href: '#',
            current: true,
            visible: userCan('leads.list'),
          },
        ]
      : page === 'Deleted Leads'
      ? [
          {
            name: 'Deleted Leads',
            href: '#',
            current: true,
            visible: userCan('leads.list'),
          },
        ]
      : page === 'Archived'
      ? [
          {
            name: 'Unqualified',
            href: '#',
            count: '',
            options: ['Unqualified'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'Duplicate',
            href: '#',
            count: '',
            options: ['Duplicate'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'LinkedIn Archived',
            href: '#',
            count: '',
            options: ['No LinkedIn Available'],
            current: true,
            visible: userCan('leads.list'),
          },
          {
            name: 'Client',
            href: '#',
            count: '',
            options: ['Client'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'Drip',
            href: '#',
            count: '',
            options: ['Drip'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'Inmail Declined',
            href: '#',
            count: '',
            options: ['Inmail Declined'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'Accepted Negative',
            href: '#',
            count: '',
            options: ['Accepted Negative'],
            current: false,
            visible: userCan('leads.list'),
          },
          {
            name: 'Disengaged Old Lead',
            href: '#',
            count: '',
            options: ['Disengaged Old Lead'],
            current: false,
            visible: userCan('leads.list'),
          },
        ]
      : page === 'Marketing Module'
      ? [
          {
            name: 'Lemlist',
            href: '#',
            count: '',
            options: ['Lemlist'],
            current: true,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'LinkedIn Accepted',
            href: '#',
            count: '',
            options: ['Accepted'],
            current: true,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'LinkedIn Follow-ups',
            href: '#',
            count: '',
            options: ['Follow-ups'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Lemlist Accepted',
            href: '#',
            count: '',
            options: ['Lemlist Accepted'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Lemlist Follow-ups',
            href: '#',
            count: '',
            options: ['Follow-ups'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Email Pending',
            href: '#',
            count: '',
            options: ['Email Pending'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Potential Leads',
            href: '#',
            count: '',
            options: ['Potential Leads'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Booked',
            href: '#',
            count: '',
            options: [
              'Direct-Booking',
              'Lemlist Direct-Booking',
              'Accepted Positive',
              'Accepted Neutral',
              'Accepted Negative',
              'Call-Booked',
              'RepliedTo',
            ],
            current: false,
            visible: userCan('leads.list'),
          },
        ]
      : [
          {
            name: 'Unprocessed New Leads',
            href: '#',
            count: '',
            options: ['Unprocessed New Leads'],
            current: userCan('leads.newLeads'),
            visible: userCan('leads.newLeads'),
            countRed: true,
          },
          {
            name: 'Old-Leads',
            href: '#',
            count: '',
            options: ['Old-Leads'],
            current: userCan('leads.oldLeads') && !userCan('leads.newLeads'),
            visible: userCan('leads.list') || userCan('leads.oldLeads'),
            countRed: true,
          },
          {
            name: 'Shopify',
            href: '#',
            count: '',
            options: ['Shopify'],
            current: userCan('leads.oldLeads') || !userCan('leads.newLeads'),
            visible: userCan('leads.list') || userCan('leads.oldLeads'),
            countRed: true,
          },
          {
            name: 'Instagram',
            href: '#',
            count: '',
            options: ['Instagram'],
            current: userCan('leads.oldLeads') || !userCan('leads.newLeads'),
            visible: userCan('leads.list') || userCan('leads.oldLeads'),
            countRed: true,
          },
          {
            name: 'For Approval',
            href: '#',
            count: '',
            options: ['For Approval'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Ready to Pitch',
            href: '#',
            count: '',
            options: ['Ready to Pitch'],
            current: false,
            visible: userCan('leads.pitcher'),
            countRed: true,
          },
          {
            name: 'Revision',
            href: '#',
            count: '',
            options: ['Revision'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
          {
            name: 'Pitched',
            href: '#',
            count: '',
            options: ['Pitched'],
            current: false,
            visible: userCan('leads.list'),
            countRed: true,
          },
        ].filter((tab) => tab.visible)
  );

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.leadId]);
    } else {
      let selectedRows = [...selected];
      selectedRows = selectedRows.filter((r) => r !== row.leadId);
      setSelected(selectedRows);
    }
  };

  const onSendToLemlist = async () => {
    setLoading(true);
    const response = await axios.post('/agency/leads/manual-lemlist', {
      leadIds: selected,
    });
    setLoading(false);
    setIsOpenLemlist(false);
  };
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: 'bg-red-50 bg-opacity-50',
    selectColumnStyle: {
      padding: '0.5rem 1rem',
      verticalAlign: 'top',
    },
    onSelect,
    hideSelectAll: true,
    nonSelectable:
      leads && leads.rows
        ? leads.rows
            .filter(
              (lead) =>
                lead.status === 'Lemlist' ||
                (lead.status === 'Pitched' &&
                  (!lead.competitor ||
                    (lead.competitor && !lead.competitor[0]?.fileId)))
            )
            .map((lead) => lead.leadId)
        : [],
  };

  const approvedStatus = [
    'For Approval',
    'Ready to Pitch',
    'Pitched',
    'Booked',
    'Follow Up 1',
    'Follow Up 2',
    'Follow Up 3',
    'Follow Up 4',
    'Accepted Positive',
    'Accepted Neutral',
    'Lemlist Accepted Positive',
    'Lemlist Accepted Neutral',
    'Lemlist Accepted Negative',
    'Lemlist',
    'New Response',
  ];

  const lemlistStatus = [
    'Pitched',
    'Booked',
    'Follow Up 1',
    'Follow Up 2',
    'Follow Up 3',
    'Follow Up 4',
    'Accepted Positive',
    'Accepted Neutral',
    'Lemlist Accepted Positive',
    'Lemlist Accepted Neutral',
    'Lemlist Accepted Negative',
    'Lemlist',
  ];

  const addBusinessDays = (date, days) => {
    var d = moment(new Date(date)).add(Math.floor(days / 5) * 7, 'd');
    var remaining = days % 5;
    while (remaining) {
      d.add(1, 'd');
      if (d.day() !== 0 && d.day() !== 6) remaining--;
    }
    return d;
  };

  const defaultColumn = [
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true,
      show: true,
      hideable: false,
      headerStyle: {
        minWidth: '200px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
    },
    {
      dataField: 'lead',
      text: 'Lead Name',
      headerStyle: {
        minWidth: '180px',
      },
      show: true,
      hideable: false,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {row
              ? `${row.lead ? row.lead : ''} ${
                  row.leadLastName ? row.leadLastName : ''
                }`
              : ''}
          </span>
        );
      },
    },
    {
      dataField: 'source',
      text: 'Lead Source',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: true,
      hideable: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: true,
      hideable: true,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell}{' '}
            {cell === 'Pitched' && row.pitchedVia ? (
              <Badge
                classes="bg-green-100 text-green-600 capitalize rounded-2xl text-xs"
                rounded="md"
                textSize="xsm"
                padding="px-3 py-1"
              >
                {row.pitchedVia}
              </Badge>
            ) : (
              ''
            )}
          </span>
        );
      },
    },
    {
      dataField: 'currentEarnings',
      text: 'ASIN Revenue',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'storeFrontEarnings',
      text: 'Storefront Revenue',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? floatFormatter(cell) : ''}
          </span>
        );
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'noOfAsin',
      text: 'No of ASINs',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: true,
      hideable: true,
    },
    {
      dataField: 'marketplace',
      text: 'Marketplace',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: true,
      hideable: true,
    },

    {
      dataField: 'productCategory',
      text: 'Product Category',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'subCategory2',
      text: 'SubCategory 2',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'asinFullTitle',
      text: 'ASIN Full Title',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'leadType',
      text: 'Lead Type',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'liAccountUsed',
      text: 'Linkedin Account',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: approvedStatus.includes(params.statuses),
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{cell ? `${cell.name}` : ''}</span>
        );
      },
      hideable: true,
    },
    {
      dataField: 'instagram',
      text: 'Instagram',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'facebook',
      text: 'Facebook',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'decisionMakersEmail',
      text: 'Decision Makers Email',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: false,
      hideable: true,
    },

    {
      dataField: 'addedBy',
      text: 'Leads Rep',
      headerStyle: {
        minWidth: '180px',
      },
      sort: true,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? `${cell.firstName} ${cell.lastName}` : ''}
          </span>
        );
      },
      show: true,
      hideable: true,
    },
    {
      dataField: 'pitchedByUser',
      text: 'Pitcher',
      // sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      sort: true,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? `${cell.firstName} ${cell.lastName}` : ''}
          </span>
        );
      },
      show: approvedStatus.includes(params.statuses),
      hideable: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Updated Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: true,
      hideable: true,
    },
    {
      dataField: 'pitchDate',
      text: 'Processed Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: approvedStatus.includes(params.statuses),
      hideable: true,
    },
    {
      dataField: 'pitchedDate',
      text: 'Pitched Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: approvedStatus.includes(params.statuses),
      hideable: true,
    },
    {
      dataField: 'lemlistDate',
      text: 'Lemlist Sent Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: lemlistStatus.includes(params.statuses),
      hideable: true,
    },
    {
      dataField: 'lemlistResponseDate',
      text: 'Lemlist Response Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: lemlistStatus.includes(params.statuses),
      hideable: true,
    },

    {
      dataField: 'dateBooked',
      text: 'Date Booked',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'dateOfCall',
      text: 'Date Call',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'dateTimeOfResponse',
      text: 'Response Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: false,
      hideable: true,
    },
    {
      dataField: 'dateTimeWeResponded',
      text: 'Our Response Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
      show: false,
      hideable: true,
    },

    {
      dataField: 'dueDate',
      text: 'Due Date',
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        let due = addBusinessDays(
          moment(row.updatedAt).format('MM-DD-YYYY'),
          row.status.includes('4') ? 14 : 3
        ).format('YYYY-MM-DD');

        let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

        let isDue = due < tomorrow;

        let dueAgo = moment(due).fromNow();
        return (
          <span className={`font-normal text-${isDue ? 'red' : 'gray'}-600`}>
            {isDue ? dueAgo : due}{' '}
            {row.isFollowUpSent && (
              <Badge
                classes="bg-green-100 text-green-600 capitalize rounded-2xl text-xs"
                rounded=""
                textSize=""
                color=""
                padding="px-3 py-1.5"
              >
                Sent
              </Badge>
            )}
          </span>
        );
      },
      show: ['Follow-ups'].includes(params.statuses),
      hideable: true,
    },
    {
      dataField: 'isDue',
      text: 'Overdue',
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        let due = addBusinessDays(
          moment(row.updatedAt).format('MM-DD-YYYY'),
          row.status.includes('4') ? 14 : 3
        ).format('YYYY-MM-DD');

        let today = moment().format('YYYY-MM-DD');

        let isDue = due < today;
        return (
          <>
            {isDue && (
              <Badge
                classes="bg-red-100 text-red-600 capitalize rounded-2xl text-xs"
                rounded=""
                textSize=""
                color=""
                padding="px-3 py-1.5"
              >
                Overdue
              </Badge>
            )}
          </>
        );
      },
      show: ['Follow-ups'].includes(params.statuses),
      hideable: true,
    },
  ];

  const getLeads = async () => {
    setLoading(true);

    await axios
      .get(`/agency/leads`, {
        params: {
          ...params,
          // statuses,
          podId: me.memberId ? me.memberId.podId : '',
          leadsRep: me.userId,
          selectedDates,
        },
      })
      .then((res) => {
        dispatch(setLeadsPaginationParams(params));
        setLeads(res.data.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (isSkipGet) setSkipGet(false);
    if (!isSkipGet) {
      ReactTooltip.rebuild();

      async function getData() {
        await getLeads();
      }

      getData();
    }
  }, [params, navTabs, selectedDates]);

  useEffect(() => {
    setSelected([]);
  }, [navTabs]);

  useEffect(() => {
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
    // had to check this for previous records that are using JSON.stringify
    const columnConfig =
      me.settings?.leadColumnsConfig &&
      isJsonString(me.settings?.leadColumnsConfig)
        ? JSON.parse(me.settings?.leadColumnsConfig)
        : me.settings?.leadColumnsConfig
        ? me.settings?.leadColumnsConfig
        : [];
    if (defaultColumn.length === columnConfig.length) {
      for (let i = 0; i < defaultColumn.length; i++) {
        defaultColumn[i].show = columnConfig[i].show;
      }
    }
    setTableColumns(defaultColumn);
  }, [navTabs]);

  useEffect(() => {
    setLoading(true);
    let myTabs = [...navTabs];

    let currenTab = myTabs.find((t) => t.current === true);
    currenTab.current = false;
    let selectedTab = myTabs.find((t) => t.name === params.statuses);
    if (selectedTab) {
      selectedTab.current = true;
    } else {
      currenTab.current = true;
    }
    // if (!location.pathname.includes('view') && paid) setIsOpenLogin(true);
    let statuses = location.pathname.includes('leads-no-seller')
      ? 'No Seller Info'
      : location.pathname.includes('deleted-leads')
      ? 'Deleted Leads'
      : location.pathname.includes('leads-archived')
      ? 'No LinkedIn Available'
      : location.pathname.includes('leads-data')
      ? 'All'
      : location.pathname.includes('leads-marketing-module')
      ? 'Lemlist'
      : 'Unprocessed New Leads';
    updateParams({
      ...params,
      statuses,
      search: '',
      fields: page === 'Data' ? 'companyName' : 'all',
      pitcher: userCan('leads.pitcher') || userCan('leads.approve'),
    });

    setLoading(false);
    setSearching(false);

    if (page === 'My Workplace' || page === 'Data') {
      let countParams = {
        leadsRep: me.userId,
      };
      axios
        .get(`/agency/leads/count`, {
          params: countParams,
        })
        .then((res) => {
          let resCount = res.data.data;
          setAssignToMe(resCount.AssignedToMe.rows);
        });
    }
  }, []);

  const onViewProfile = (row) => {
    let assignToMeByStatus = assignToMe.filter(
      (el) => el.status === row.status
    );

    if (
      assignToMeByStatus.length > 9 &&
      ['Unprocessed New Leads', 'Old-Leads'].includes(row.status) &&
      !assignToMeByStatus.find((el) => el.leadId === row.leadId)
    ) {
      assignToMeByStatus.map((rec) => {
        dispatch(
          setAlert(
            'info',
            `In-progress in ${rec.status}`,
            `Company name: ${rec.companyName}`,
            12000,
            `/leads/profile/${rec.leadId}`
          )
        );
      });
    } else if (
      !userCan('leads.pitcher') &&
      !userCan('leads.approve') &&
      !page === 'Archived' &&
      row.addedBy &&
      row.addedBy.userId !== me.userId
    ) {
      dispatch(
        setAlert(
          'info',
          `In-progress`,
          `Leads Rep: ${row.addedBy?.firstName} ${row.addedBy?.lastName}`
        )
      );
    } else {
      history.push(
        `/${
          page === 'Marketing Module' ? 'leads-marketing-module' : 'leads'
        }/profile/${row.leadId}`
      );
    }
  };

  const [tableColumns, setTableColumns] = useState(defaultColumn);

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField ? sortField : 'updatedAt'}:${sortOrder}`,
    });
  };

  const onTabChange = (tab) => {
    updateParams({
      statuses: tab.name,
      status: '',
    });
  };

  const fieldOptions = [
    { label: 'All', value: 'all' },
    { label: 'Company Name', value: 'companyName' },
    { label: 'Lead Name', value: 'leadName' },
    { label: 'Leads Rep', value: 'addedBy' },
    { label: 'ASIN', value: 'asin' },
    { label: 'Email', value: 'email' },
    { label: 'Website', value: 'website' },
    { label: 'Product Category', value: 'productCategory' },
    { label: 'Ready to pitch Date', value: 'approvedDate' },
    { label: 'Pitched Date', value: 'pitchedDate' },
    { label: 'Booked Date', value: 'dateBooked' },
    { label: 'Creation Date', value: 'createdAt' },
    { label: 'Status Update Date', value: 'updatedAt' },
    { label: 'ASIN Price', value: 'asinPrice' },
    { label: 'ASIN Review', value: 'asinReviews' },
    { label: 'No of ASINs', value: 'noOfAsin' },
    { label: 'Marketplace', value: 'marketplace' },
    { label: 'ASIN Revenue', value: 'currentEarnings' },
    { label: 'Storefront Revenue', value: 'storeFrontEarnings' },
    { label: 'Competitor ASIN Revenue', value: 'revenue' },
    { label: 'Linkedin Account', value: 'liAccountUsed' },
    { label: 'Status', value: 'status' },
  ];

  const fieldDate = [
    'approvedDate',
    'dateBooked',
    'pitchedDate',
    'createdAt',
    'updatedAt',
  ];

  const fieldPrice = [
    'asinPrice',
    'asinReviews',
    'currentEarnings',
    'storeFrontEarnings',
    'revenue',
    'noOfAsin',
  ];

  const fieldSelect = ['status'];

  const statusOptions =
    params.statuses === 'Old-Leads'
      ? ['Old-Leads']
      : params.statuses === 'Unqualified'
      ? [
          'Out of Stock',
          'SF under $1000',
          'Reseller',
          'Chinese Seller',
          'Linkedin Messaging Disabled',
          'Unqualified',
          'No Competitor',
          'AMZ',
          'Other Marketplace',
        ]
      : params.statuses === 'Duplicate'
      ? ['Duplicate', 'System-duplicate', 'Potential-duplicate']
      : params.statuses === 'Inmail Declined'
      ? ['Inmail Declined']
      : params.statuses === 'Drip'
      ? ['Drip']
      : params.statuses === 'LinkedIn Archived'
      ? ['No LinkedIn Available']
      : params.statuses === 'Unprocessed New Leads'
      ? ['Unprocessed New Leads']
      : params.statuses === 'Shopify'
      ? ['Shopify']
      : params.statuses === 'New Leads'
      ? ['New Leads']
      : params.statuses === 'For Approval'
      ? ['For Approval']
      : params.statuses === 'Potential Leads'
      ? ['Potential Leads']
      : params.statuses === 'Ready to Pitch'
      ? ['Ready to Pitch', 'Connection Request Sent']
      : params.statuses === 'Rejected'
      ? ['Rejected']
      : params.statuses === 'Revision'
      ? ['Revision']
      : params.statuses === 'Pitched'
      ? ['Pitched']
      : params.statuses === 'Follow-ups'
      ? ['Follow Up 1', 'Follow Up 2', 'Follow Up 3', 'Follow Up 4']
      : params.statuses === 'Client'
      ? ['Client']
      : params.statuses === 'Accepted'
      ? ['Accepted Positive', 'Accepted Neutral']
      : // : params.statuses === 'Accepted Neutral'
      // ? ['Accepted Neutral']
      params.statuses === 'Accepted Negative'
      ? [
          'Accepted Negative',
          'Accepted Disqualified',
          'Not Interested',
          'Do Not Contact',
          'Working with Another Agency',
          'Incorrect Contact',
          'Previous / Existing Client',
        ]
      : params.statuses === 'Booked'
      ? [
          'Direct-Booking',
          'Lemlist Direct-Booking',
          'Call-Booked',
          'RepliedTo',
          'Booked',
        ]
      : [
          'Old-Leads',
          'Out of Stock',
          'SF under $1000',
          'Reseller',
          'Chinese Seller',
          'Linkedin Messaging Disabled',
          'Unqualified',
          'No Competitor',
          'Disengaged Old Lead',
          'AMZ',
          'Other Marketplace',
          'Duplicate',
          'System-duplicate',
          'Potential-duplicate',
          'Inmail Declined',
          'Drip',
          'No LinkedIn Available',
          'Unprocessed New Leads',
          'Shopify',
          'New Leads',
          'For Approval',
          'Potential Leads',
          'Ready to Pitch',
          'Connection Request Sent',
          'Rejected',
          'Revision',
          'Follow Up 1',
          'Follow Up 2',
          'Follow Up 3',
          'Follow Up 4',
          'Client',
          'Accepted',
          'Accepted Neutral',
          'Accepted Negative',
          'Accepted Disqualified',
          'Not Interested',
          'Do Not Contact',
          'Working with Another Agency',
          'Incorrect Contact',
          'Previous / Existing Client',
          'Direct-Booking',
          'Lemlist Direct-Booking',
          'Call-Booked',
          'RepliedTo',
          'Booked',
          'UNL Expired Leads',
        ];
  const [searchTerm, setSearchTerm] = useState('');
  const onChangeField = (e) => {
    updateParams({
      ...params,
      fields: e.target.value,
    });
  };

  useEffect(() => {
    if (isSearching) {
      const handleSearch = debounce((value) => {
        updateParams({
          ...params,
          search: value,
        });
      }, 1000);

      handleSearch(searchTerm);
      return () => handleSearch.cancel();
    }
  }, [searchTerm]);

  const handleChange = (event) => {
    setSearching(true);
    const { value } = event.target;
    setSearchTerm(value);
  };

  const getCsv = async () => {
    setLoading(true);
    await axios
      .get(`/agency/leads/export`, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        if (res.data !== 'no data') {
          const blob = new Blob([res.data], {
            type: res.headers['content-type'],
            encoding: 'UTF-8',
          });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `leads-${moment().format('YYYY-MM-DD')}.csv`;
          link.click();
        }

        setLoading(false);
      });
  };

  const onExport = () => {
    async function getData() {
      await getCsv(true);
    }
    getData();
  };

  return (
    <>
      <PageHeader
        title={
          page === 'My Workplace'
            ? 'Leads'
            : page === 'Data'
            ? 'All Records'
            : page === 'Marketing Module'
            ? 'Linkedin Leads'
            : 'Archive'
        }
        // tabs={page === 'My Workplace' ? tabs.filter((e) => e.isTab) : []}
        containerClasses={''}
        left={
          userCan('leads.create') &&
          page === 'My Workplace' && (
            <>
              {userCan('leads.create') && (
                <button
                  className="flex items-center uppercase px-2 rounded-sm py-1 border border-gray-300 text-xs text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
                  onClick={() => {
                    history.push(`/leads/profile/create`);
                  }}
                >
                  <PlusIcon className="h-4 w-4" /> Add
                </button>
              )}
              {userCan('leads.upload.unassignedLeads') && (
                <Link
                  to="/leads/import"
                  className="flex items-center uppercase px-2 rounded-sm py-1 border bg-red-500 hover:bg-red-600 border-red-300 text-xs text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300 ml-2"
                >
                  <DocumentDownloadIcon className="inline w-4 h-4 mr-1" />{' '}
                  Import
                </Link>
              )}
            </>
          )
        }
      />
      <div className="sm:grid sm:grid-cols-4 gap-4 mb-4 items-center pt-5">
        {fieldDate.includes(params.fields) ? (
          <div className="sm:col-span-1">
            <DatePicker position="left" />
          </div>
        ) : fieldPrice.includes(params.fields) ? (
          <div className="sm:col-span-1">
            <div className="flex">
              <div className="px-2">
                <Label htmlFor="search">Min</Label>
                <Input
                  name="minimum"
                  value={params.minPrice}
                  onChange={(e) =>
                    updateParams({
                      ...params,
                      minPrice: e.target.value,
                    })
                  }
                  type="number"
                  placeholder={'minimum'}
                />
              </div>

              <div className="px-2">
                <Label htmlFor="search">Max</Label>
                <Input
                  name="maximum"
                  value={params.maxPrice}
                  onChange={(e) =>
                    updateParams({
                      ...params,
                      maxPrice: e.target.value,
                    })
                  }
                  type="number"
                  placeholder={'maximum'}
                />
              </div>
            </div>
          </div>
        ) : fieldSelect.includes(params.fields) ? (
          <div className="sm:col-span-1">
            <Label htmlFor="select">Select Status</Label>
            <Select
              name="select"
              className={`appearance-none px-3 py-2 border shadow-sm placeholder-gray-400 focus:ring-red-500 focus:border-red-500 `}
              onChange={(e) =>
                updateParams({
                  ...params,
                  status: e.target.value,
                })
              }
              value={params.status}
            >
              {statusOptions.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </Select>
          </div>
        ) : (
          <div className="sm:col-span-1">
            <Label htmlFor="search">Search</Label>
            <Input
              name="search"
              value={searchTerm}
              onChange={handleChange}
              type="text"
              placeholder={'Search'}
            />
          </div>
        )}

        <div className="sm:col-span-1">
          <Label htmlFor="field">Search Field</Label>
          <Select
            name="field"
            className={`appearance-none px-3 py-2 border shadow-sm placeholder-gray-400 focus:ring-red-500 focus:border-red-500 `}
            onChange={onChangeField}
            value={params.fields}
          >
            {fieldOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="sm:col-span-1">
          <Label htmlFor="column_picker">Column Metrics</Label>
          <ColumnPicker
            options={tableColumns}
            setTableColumns={setTableColumns}
          />
        </div>
        {userCan('leads.manager') && (
          <div className="sm:col-span-1">
            <div className="flex justify-end">
              <Button
                type="submit"
                loading={loading}
                showLoading={true}
                onClick={onExport}
                classes="mt-5"
              >
                Export
              </Button>
            </div>
          </div>
        )}
      </div>
      <TabNav
        tabs={navTabs}
        setTabs={setNavTabs}
        onClick={(tab) => onTabChange(tab)}
        theme="tabbed"
      />
      {page !== 'Deleted Leads' && selected && selected.length > 0 && (
        <div className="py-5 flex gap-2">
          <DropdownMenu
            title={
              <Button classes="bg-red-500 hover:bg-red-600 border-red-300 text-xs text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300">
                Action
                <DotsVerticalIcon className="w-6 h-6 text-gray-500 hover:text-gray-900" />
              </Button>
            }
            titleClasses="flex items-center mr-1"
            button
            buttonBg="bg-transparent"
            buttonFontWeight="font-normal"
            hoverClasses="bg-none"
            textColor="text-gray-600"
            classes="text-sm"
            buttonRounded=""
            hoverText="hover:text-red-500"
            dropdownWidth="w-48"
            padding=""
            position=""
            hideArrow
          >
            <div className="px-1 py-1 flex flex-col">
              {userCan('leads.responses') && params.statuses === 'Pitched' && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setIsOpenLemlist(true)}
                      className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                    >
                      Send to Lemlist
                    </button>
                  )}
                </Menu.Item>
              )}
              {userCan('leads.delete') && params.statuses !== 'Pitched' && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setIsChangeStatusFormOpen(true)}
                      className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                    >
                      Change Status
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </DropdownMenu>
        </div>
      )}
      <Modal
        open={isChangeStatusFormOpen}
        setOpen={setIsChangeStatusFormOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <ChangeStatusModal
          setOpen={setIsChangeStatusFormOpen}
          records={selected}
          getLeads={getLeads}
          resetSelected={setSelected}
        />
      </Modal>
      <ConfirmationModal
        title="Lemlist Campaign"
        content="Move to lemlist campaign"
        open={isOpenLemlist}
        setOpen={setIsOpenLemlist}
        onOkClick={onSendToLemlist}
        onCancelClick={() => setIsOpenLemlist(false)}
        okLoading={loading}
        showOkLoading={true}
      />
      <Table
        columns={[
          ...tableColumns.filter((el) => el.show),
          {
            dataField: 'action',
            text: 'Action',
            className: 'text-center',
            formatter: (cell, row) => {
              return userCan('leads.view') && page !== 'Deleted Leads' ? (
                <>
                  {page !== 'Data' ? (
                    <button
                      onClick={() => onViewProfile(row)}
                      data-tip="Profile"
                      disabled={
                        !userCan('leads.profile.view') ||
                        (PRE_PITCH_STATUSES.includes(params.statuses) &&
                          row.addedBy !== null &&
                          row.addedBy.userId !== me.userId &&
                          (row.addedBy.userId !== null ||
                            row.addedBy.userId !== ''))
                      }
                    >
                      <ReactTooltip
                        place="top"
                        className="max-w-xs text-black"
                        backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                        textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                      />
                      <UserCircleIcon
                        className="m-1 h-5 w-5"
                        color={
                          assignToMe.filter(
                            (el) => el.status === params.statuses
                          ).length > 9 &&
                          !assignToMe.find((el) => el.leadId === row.leadId) &&
                          PRE_PITCH_STATUSES.includes(params.statuses)
                            ? 'grey'
                            : 'green'
                        }
                      />
                    </button>
                  ) : (
                    <button
                      onClick={() => onViewProfile(row)}
                      data-tip="Profile"
                      disabled={!userCan('leads.responses')}
                    >
                      <ReactTooltip
                        place="top"
                        className="max-w-xs text-black"
                        backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                        textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                      />
                      <UserCircleIcon
                        className="m-1 h-5 w-5"
                        color={userCan('leads.responses') ? 'green' : 'grey'}
                      />
                    </button>
                  )}
                </>
              ) : (
                ''
              );
            },
          },
        ]}
        data={leads}
        onTableChange={onTableChange}
        params={params}
        keyField="leadId"
        defaultSorted={[
          {
            dataField: sortParam ? sortParam[0] : 'updatedAt',
            order: sortParam ? sortParam[1] : 'desc',
          },
        ]}
        loading={loading}
        {...(userCan('leads.list') && {
          selectRow,
        })}
      />
    </>
  );
};

export default Overview;
