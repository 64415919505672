import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import useBodyClass from 'hooks/useBodyClass';

import { fetchTaskItems, fetchUsers } from '../writingSlice';

import TaskGroup from './TaskGroup';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import usePermissions from 'hooks/usePermissions';

const TaskWorkspace = ({ code, squadId = '', podId = '', params }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const [formattedItems, setFormattedItems] = useState({});

  const taskPermissions = {
    canDeleteTask: userCan('writing.task.delete'),
    canAddEmail: userCan('writing.task.email'),
    canUseTimer: userCan('writing.task.timer'),
    canAddFiles: userCan('writing.task.files.add'),
    canAddDueDate: userCan('writing.task.due.add'),
    canUpdateName: userCan('writing.task.title.update'),
    canAddAssignee: userCan('writing.task.assignee.add'),
    canAddComments: userCan('writing.task.comments.add'),
    canRemoveFiles: userCan('writing.task.files.remove'),
    canUpdateStatus: userCan('writing.task.status.update'),
    canUpdateDueDate: userCan('writing.task.due.update'),
    canAddSubtasks: userCan('writing.task.subTasks.add'),
    canEditSubtasks: userCan('writing.task.subTasks.edit'),
    canDeleteSubtasks: userCan('writing.task.subTasks.delete'),
    canRemoveAssignee: userCan('writing.task.assignee.remove'),
    canAddPriority: userCan('writing.task.prioritization.add'),
    canAddDescription: userCan('writing.task.description.add'),
    canUpdatePriority: userCan('writing.task.prioritization.update'),
    canUpdateDescription: userCan('writing.task.description.update'),
  };

  const { taskListStatuses, items, users, boardView } = useSelector(
    (state) => state.writing
  );

  useBodyClass(['tasks-light', 'tasks', boardView]);

  useEffect(() => {
    let groupParams = {};

    if (podId) {
      groupParams.assigneesGroupPodId = podId;
    }

    if (squadId) {
      groupParams.assigneesGroupSquadId = squadId;
    }

    dispatch(
      fetchTaskItems({
        ...params,
        ...groupParams,
        taskListCodes: [code],
        scopes: ['owned'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  }, [code, params, podId, squadId]);

  useEffect(() => {
    let groupParams = {};

    if (podId) {
      groupParams.groupPodId = podId;
    }

    if (squadId) {
      groupParams.groupSquadId = squadId;
    }

    dispatch(
      fetchUsers({
        ...groupParams,
        pageSize: 1000,
        sort: 'firstName:asc',
        permissionAccesses: [`writing.${code}.task.assignable`],
      })
    );
  }, [code, podId]);

  useEffect(() => {
    let formatted = { ...items };

    if (
      boardView === 'kanban' &&
      (params.groupBy === 'status' || params.groupBy === 'priority')
    ) {
      let newFormatted = {};

      const columns = {
        priority: ['No priority', 'low', 'normal', 'high'],
        status: taskListStatuses[code].map((i) => i.name),
      };

      columns[params.groupBy].forEach((key) => {
        newFormatted[key] = formatted[key] ?? [];
      });

      formatted = newFormatted;
    }

    setFormattedItems(formatted);
  }, [items, params.groupBy, boardView]);

  const refreshList = () => {
    let groupParams = {};

    if (podId) {
      groupParams.assigneesGroupPodId = podId;
    }

    if (squadId) {
      groupParams.assigneesGroupSquadId = squadId;
    }

    dispatch(
      fetchTaskItems({
        ...params,
        ...groupParams,
        taskListCodes: [code],
        scopes: ['owned'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {!isArray(formattedItems) && (
        <div className="w-800 xl:w-full list-container flex-1">
          {isEmpty(formattedItems) ? (
            <p className="text-grayscale-800 text-lg font-inter font-medium tracking-3/4 text-center mt-8">
              No tasks found
            </p>
          ) : (
            Object.keys(formattedItems).map((groupKey) => {
              return (
                <TaskGroup
                  key={groupKey}
                  groupName={groupKey}
                  tabText={groupKey}
                  data={formattedItems[groupKey]}
                  onChange={refreshList}
                  status={groupKey}
                  groupBy={params.groupBy}
                  allowAddTask={false}
                  assigneeOptions={users.rows}
                  permissions={taskPermissions}
                  canDrag={
                    {
                      status: taskPermissions.canUpdateStatus,
                      priority:
                        taskPermissions.canUpdatePriority ||
                        taskPermissions.canAddPriority,
                      due:
                        taskPermissions.canUpdateDueDate ||
                        taskPermissions.canAddDueDate,
                    }[params.groupBy]
                  }
                />
              );
            })
          )}
        </div>
      )}

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={refreshList}
                assigneeOptions={users.rows}
                notifyAssignee={true}
                permissions={taskPermissions}
                serviceAsinData={
                  code === 'client_copy_writing_task_list'
                    ? {
                        name: 'service-asin',
                        type: 'service-asin',
                        title: 'Service ASIN',
                        checklists: [
                          {
                            name: 'Content work needed',
                            description: 'Content work needed',
                            items: [
                              {
                                value: 'Listing Copy',
                              },
                              {
                                value: 'A+ Copy',
                              },
                            ],
                          },
                        ],
                      }
                    : {}
                }
              />
            );
          }}
        />
      </Switch>
    </DndProvider>
  );
};

export default TaskWorkspace;
