import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/outline';

import {
  fetchUsers,
  fetchTaskItems,
  fetchTaskListClients,
} from 'features/design/designSlice';

import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import NewTask from 'features/tasks/components/NewTask';
import TaskGroup from './TaskGroup';
import useBodyClass from 'hooks/useBodyClass';

import usePermissions from 'hooks/usePermissions';

const OpenTaskList = ({ code, params = {} }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { userCan } = usePermissions();

  const [addTask, setAddTask] = useState(false);

  const { list, items, boardView, users } = useSelector(
    (state) => state.design
  );

  useBodyClass(['tasks-light', 'tasks', boardView]);

  useEffect(() => {
    dispatch(
      fetchTaskListClients({
        codes: [code],
        pageSize: 1000,
        include: ['folder.space.client'],
        scopes: ['spaceByActiveClients'],
        sort: 'folder.space.client.client:asc',
        attributes: ['taskListId', 'taskFolderId', 'code', 'name'],
      })
    );
  }, [code]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 1000,
        sort: 'firstName:asc',
        permissionAccesses: [`design.${code}.task.assignable`],
      })
    );
  }, [code]);

  useEffect(() => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        statuses: ['Open', 'Incomplete'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  }, [code, params]);

  const refreshList = () => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        statuses: ['Open', 'Incomplete'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  };

  return (
    <div className="w-800 xl:w-full list-container flex-1">
      {isEmpty(items) ? (
        <div className="list-group">
          {userCan('design.client_design_task_list.task.create') && (
            <div className="flex flex-col">
              <div className="sm:grid grid-cols-1 border-b border-gray-50">
                {!addTask ? (
                  <div className="flex items-center px-6 w-full">
                    <button
                      onClick={() => setAddTask(true)}
                      className="text-gray-500 hover:text-gray-700 font-bold tracking-widest text-sm flex items-center"
                    >
                      <PlusIcon className="w-5 h-5 inline mr-1" /> New Task
                    </button>
                  </div>
                ) : (
                  <NewTask
                    cancel={() => setAddTask(false)}
                    created={refreshList}
                    folder="design"
                    status="Open"
                    assigneeOptions={users.rows}
                    taskListOptions={list.rows}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : !isArray(items) ? (
        Object.keys(items).map((groupKey) => {
          return (
            <TaskGroup
              key={groupKey}
              groupName={groupKey}
              tabText={groupKey}
              data={items[groupKey]}
              onChange={refreshList}
              status={groupKey}
              groupBy={params.groupBy}
              allowAddTask={userCan(
                'design.client_design_task_list.task.create'
              )}
              assigneeOptions={users.rows}
              allowedStatuses={['Open', 'Incomplete']}
            />
          );
        })
      ) : (
        ''
      )}

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={refreshList}
                assigneeOptions={users.rows}
                notifyAssignee={true}
                allowedStatuses={['Open', 'Incomplete']}
                serviceAsinData={{
                  name: 'service-asin',
                  type: 'service-asin',
                  title: 'Service ASIN',
                  checklists: [
                    {
                      name: 'Design work needed',
                      description: 'Design work needed',
                      items: [
                        { value: 'Listing Images' },
                        { value: 'A+ Copy' },
                        { value: 'Storefront' },
                        { value: 'Listing Video' },
                      ],
                    },
                  ],
                }}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default OpenTaskList;
