import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import Sales from 'features/sales/Sales';
import { SalesMetrics } from 'features/sales/Metrics';
import { Route } from 'react-router-dom';
import Preview from 'features/sales/Quote/Preview';
import Show from 'features/sales/Quote/Show';

const SalesRoutes = [
  <PrivateRoute
    path="/sales"
    component={Sales}
    layout={AgencyLayout}
    access={['agency']}
    permissions="sales.profiles.list"
  />,

  <PrivateRoute
    path="/sales-metrics/:salesId/:salesName"
    component={SalesMetrics}
    layout={AgencyLayout}
    access={['agency']}
    permissions="sales.metrics"
  />,

  <PrivateRoute
    path="/sales-metrics"
    component={SalesMetrics}
    layout={AgencyLayout}
    access={['agency']}
    permissions="sales.metrics"
  />,

  <PrivateRoute path="/quote/preview/:identifier" component={Preview} exact />,
  <Route path="/quote/show/:identifier" component={Show} exact />,
];
export default SalesRoutes;
