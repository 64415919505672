import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import PermissionDenied from 'features/static/PermissionDenied';
import InvalidRequest from 'features/static/InvalidRequest';
import NotFound from 'features/static/NotFound';
import { Route } from 'react-router-dom';
import Error404 from 'features/static/404';
import Maintenance from 'features/static/Maintenance';

const ErrorRoutes = [
  <Route path="/maintenance" component={Maintenance} exact />,

  <PrivateRoute
    path="/permission-denied"
    layout={AgencyLayout}
    component={PermissionDenied}
    access={['agency']}
  />,

  <Route
    path="/invalid-request"
    component={InvalidRequest}
    access={['application']}
    exact
  />,

  <PrivateRoute
    path="/not-found"
    component={NotFound}
    layout={AgencyLayout}
    access={['agency']}
    exact
  />,
  <Route render={() => <Error404 />} />,
];
export default ErrorRoutes;
