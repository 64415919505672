import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import {
  fetchMaintenanceSettings,
  updateMaintenanceSettings,
} from 'features/settings/settingsSlice';
import { setAlert } from 'features/alerts/alertsSlice';

import usePermissions from 'hooks/usePermissions';

import image from 'assets/images/maintenance.svg';

const Maintenance = ({ history }) => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();

  useEffect(() => {
    dispatch(fetchMaintenanceSettings()).then(({ payload: status }) => {
      if (!status) {
        history.push('/');
      }
    });
  }, [dispatch]);

  const disableMaintenance = () => {
    dispatch(updateMaintenanceSettings(false)).then(({ error }) => {
      if (error) {
        dispatch(setAlert('error', 'Failed to update maintenance status'));
        return;
      }

      history.push('/');
    });
  };

  return (
    <div className="text-center flex flex-col items-center justify-center w-screen h-screen">
      <div className="flex items-center justify-center">
        <img className="maintenance mode" src={image} />
      </div>
      <p className="mt-6 font-bold font-inter text-40 leading-1.2 tracking-1 text-grayscale-900">
        System Maintenance
      </p>
      <p className="mt-5 font-normal font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900">
        The Better Seller Dashboard is undergoing maintenance. <br /> We’ll send
        an email once the system is back up and running.
      </p>
      <p className="mt-5 text-base font-sourceSansPro text-grayscale-800 leading-1.5">
        We apologize for any inconvenience while we make improvements to our
        systems.
      </p>

      {userCan('settings.maintenance.update') && (
        <PowerSettingsNewIcon
          style={{ fontSize: '64px' }}
          className="absolute bottom-5 right-5 text-white bg-red-500 rounded-full p-3 animate-bounce cursor-pointer"
          onClick={disableMaintenance}
        />
      )}
    </div>
  );
};

export default withRouter(Maintenance);
