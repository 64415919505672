import axios from 'axios';
import moment from 'moment';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useQueryParams from 'hooks/useQueryParams';

import { fetchTaskItems, fetchUsers } from '../designSlice';

import TabNav from 'components/TabNav';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import TaskManagerAvatar from 'features/tasks/components/TaskManagerAvatar';
import TaskDueDateDropdown from 'features/tasks/components/TaskDueDateDropdown';
import PriorityDropdown from 'features/tasks/components/PriorityDropdown';

const TaskSummary = ({ code, podId }) => {
  const dispatch = useDispatch();
  const { taskListStatuses, items, users } = useSelector(
    (state) => state.design
  );

  const [statuses, setStatuses] = useState(
    taskListStatuses.map((status, i) => {
      return {
        name: status.name,
        value: status.name,
        current: i === 0,
        className: 'text-base',
      };
    })
  );

  const { params, updateParams } = useQueryParams({
    search: '',
    status: 'Open',
  });

  useEffect(() => {
    updateParams({ ...params, status: 'Open' });
  }, []);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 1000,
        groupPodId: podId,
        sort: 'firstName:asc',
        permissionAccesses: [`design.${code}.task.assignable`],
      })
    );
  }, [code, podId]);

  useEffect(() => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        assigneesGroupPodId: podId,
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  }, [code, params, podId]);

  const refreshList = () => {
    dispatch(
      fetchTaskItems({
        ...params,
        taskListCodes: [code],
        assigneesGroupPodId: podId,
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  };

  const onUpdateAssignee = async (taskItemId, user, action) => {
    axios({
      method: action === 'add' ? 'POST' : 'DELETE',
      url: `/agency/tasks/items/${taskItemId}/assignees/${user.userId}`,
    }).then(() => refreshList());
  };

  const onChangeDueDate = (taskItemId, dueDate) => {
    axios
      .put(`/agency/tasks/items/${taskItemId}`, {
        dueDate,
      })
      .then(() => refreshList());
  };

  const onChangePriority = (taskItemId, priority) => {
    axios
      .put(`/agency/tasks/items/${taskItemId}`, {
        priority: priority ? priority.status : null,
      })
      .then(() => refreshList());
  };

  return (
    <div className="mt-5">
      <TabNav
        tabs={statuses}
        setTabs={setStatuses}
        onClick={(tab) => updateParams({ status: tab.value })}
        theme="tabbed"
      />

      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500">
                Date Assigned
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-left">
                Client Name
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Assignees
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Brand Strategist
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Account Specialist
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Due Date
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Priority
              </td>
            </tr>
          </thead>

          {isArray(items) && (
            <tbody>
              {items.length ? (
                items.map((item) => {
                  return (
                    <tr key={`team-task-item-${item.taskItemId}`}>
                      <td className="text-grayscale-800 leading-1.5 px-2.5 py-5">
                        {moment(item.createdAt).format('MMMM Do, YYYY')}
                      </td>
                      <td className="text-grayscale-800 leading-1.5 px-2.5 py-5">
                        {item.list.folder.space.client.client}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <UsersPopoverMultiple
                            users={users.rows}
                            position="left-1/2 transform -translate-x-1/2"
                            onChange={(user, action) =>
                              onUpdateAssignee(item.taskItemId, user, action)
                            }
                            selectedIds={item.assignees.map(
                              (user) => user.userId
                            )}
                          />
                        </div>
                      </td>
                      <td className="px-2.5 py-5">
                        {item.list?.folder?.space?.client
                          ?.seniorAccountManager && (
                          <div className="flex justify-center">
                            <TaskManagerAvatar
                              user={
                                item.list.folder.space.client
                                  .seniorAccountManager.user
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-2.5 py-5">
                        {item.list?.folder?.space?.client?.accountManager && (
                          <div className="flex justify-center">
                            <TaskManagerAvatar
                              user={
                                item.list.folder.space.client.accountManager
                                  .user
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-2.5 py-5">
                        <div className="flex justify-center">
                          <TaskDueDateDropdown
                            value={item.dueDate}
                            setValue={(value) =>
                              onChangeDueDate(item.taskItemId, value)
                            }
                          />
                        </div>
                      </td>

                      <td className="px-2.5 py-5">
                        <div className="flex justify-center">
                          <PriorityDropdown
                            value={item.priority}
                            setValue={(value) =>
                              onChangePriority(item.taskItemId, value)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={7}
                    className="text-grayscale-800 leading-1.5 px-2.5 py-5 text-center"
                  >
                    No tickets under this status
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TaskSummary;
