import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlusIcon } from '@heroicons/react/outline';

import {
  fetchUsers,
  fetchTaskItems,
  fetchTaskListClients,
} from 'features/writing/writingSlice';

import NewTask from 'features/tasks/components/NewTask';
import TaskGroup from './TaskGroup';
import useBodyClass from 'hooks/useBodyClass';

import usePermissions from 'hooks/usePermissions';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';

const OpenTaskList = ({ code, permissionCode, params = {} }) => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { url } = useRouteMatch();

  const [addTask, setAddTask] = useState(false);

  const { list, items, boardView, users } = useSelector(
    (state) => state.writing
  );

  useBodyClass(['tasks-light', 'tasks', boardView]);

  const taskPermissions = {
    canDeleteTask: userCan('writing.task.delete'),
    canAddAssignee: userCan('writing.task.assignee.add'),
    canRemoveAssignee: userCan('writing.task.assignee.remove'),
    canAddDueDate: userCan('writing.task.due.add'),
    canUpdateDueDate: userCan('writing.task.due.update'),
    canAddSubtasks: userCan('writing.task.subTasks.add'),
    canDeleteSubtasks: userCan('writing.task.subTasks.delete'),
    canEditSubtasks: userCan('writing.task.subTasks.edit'),
    canAddPriority: userCan('writing.task.prioritization.add'),
    canUpdatePriority: userCan('writing.task.prioritization.update'),
    canAddFiles: userCan('writing.task.files.add'),
    canRemoveFiles: userCan('writing.task.files.remove'),
    canAddComments: userCan('writing.task.comments.add'),
    canAddEmail: userCan('writing.task.email'),
    canUpdateName: userCan('writing.task.title.update'),
    canAddDescription: userCan('writing.task.description.add'),
    canUpdateDescription: userCan('writing.task.description.update'),
    canUseTimer: userCan('writing.task.timer'),
    canUpdateStatus: userCan(
      `writing.pending.${permissionCode}.tasks.status.update.incomplete`
    ),
  };

  useEffect(() => {
    dispatch(
      fetchTaskListClients({
        codes: [code],
        pageSize: 1000,
        include: ['folder.space.client'],
        scopes: ['spaceByActiveClients'],
        sort: 'folder.space.client.client:asc',
        attributes: ['taskListId', 'taskFolderId', 'code', 'name'],
      })
    );
  }, [code]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 1000,
        sort: 'firstName:asc',
        permissionAccesses: [`writing.${code}.task.assignable`],
      })
    );
  }, [code]);

  useEffect(() => {
    let scopes = [];

    if (userCan(`writing.pending.${permissionCode}.tasks.owned`)) {
      scopes.push('owned');
    }

    if (userCan(`writing.pending.${permissionCode}.tasks.unassigned`)) {
      scopes.push('unassigned');
    }

    dispatch(
      fetchTaskItems({
        scopes,
        ...params,
        taskListCodes: [code],
        statuses: ['Open', 'Incomplete'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  }, [code, params, permissionCode]);

  const refreshList = () => {
    let scopes = [];

    if (userCan(`writing.pending.${permissionCode}.tasks.owned`)) {
      scopes.push('owned');
    }

    if (userCan(`writing.pending.${permissionCode}.tasks.unassigned`)) {
      scopes.push('unassigned');
    }

    dispatch(
      fetchTaskItems({
        scopes,
        ...params,
        taskListCodes: [code],
        statuses: ['Open', 'Incomplete'],
        include: ['list.folder.space.client', 'subtasks', 'assignees'],
      })
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-800 xl:w-full list-container flex-1">
        {!isArray(items) &&
          !isEmpty(items) &&
          Object.keys(items).map((groupKey) => {
            return (
              <TaskGroup
                key={groupKey}
                groupName={groupKey}
                tabText={groupKey}
                data={items[groupKey]}
                onChange={refreshList}
                status={groupKey}
                groupBy={params.groupBy}
                allowAddTask={
                  params.groupBy === 'status' &&
                  groupKey === 'Open' &&
                  userCan(`writing.pending.${permissionCode}.tasks.add`)
                }
                assigneeOptions={users.rows}
                allowedStatuses={['Open', 'Incomplete']}
                permissions={taskPermissions}
                canDrag={
                  {
                    status: taskPermissions.canUpdateStatus,
                    priority:
                      taskPermissions.canUpdatePriority ||
                      taskPermissions.canAddPriority,
                    due:
                      taskPermissions.canUpdateDueDate ||
                      taskPermissions.canAddDueDate,
                  }[params.groupBy]
                }
              />
            );
          })}

        {!isArray(items) && (
          <div className="list-group">
            {userCan(`writing.pending.${permissionCode}.tasks.add`) &&
              (params.groupBy !== 'status' ||
                (params.groupBy === 'status' && !items['Open'])) && (
                <div className="flex flex-col">
                  <div className="sm:grid grid-cols-1 border-b border-gray-50">
                    {!addTask ? (
                      <div className="flex items-center px-6 w-full">
                        <button
                          onClick={() => setAddTask(true)}
                          className="text-gray-500 hover:text-gray-700 font-bold tracking-widest text-sm flex items-center"
                        >
                          <PlusIcon className="w-5 h-5 inline mr-1" /> New Task
                        </button>
                      </div>
                    ) : (
                      <NewTask
                        cancel={() => setAddTask(false)}
                        created={refreshList}
                        folder="writing"
                        status="Open"
                        assigneeOptions={users.rows}
                        taskListOptions={list.rows}
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
        )}
        <Switch>
          <Route
            path={`${url}/:taskItemId`}
            render={() => {
              return (
                <TaskDetailsPage
                  onChange={refreshList}
                  assigneeOptions={users.rows}
                  notifyAssignee={true}
                  permissions={taskPermissions}
                  allowedStatuses={['Open', 'Incomplete']}
                  serviceAsinData={
                    code === 'client_copy_writing_task_list'
                      ? {
                          name: 'service-asin',
                          type: 'service-asin',
                          title: 'Service ASIN',
                          checklists: [
                            {
                              name: 'Content work needed',
                              description: 'Content work needed',
                              items: [
                                {
                                  value: 'Listing Copy',
                                },
                                {
                                  value: 'A+ Copy',
                                },
                              ],
                            },
                          ],
                        }
                      : {}
                  }
                />
              );
            }}
          />
        </Switch>
      </div>
    </DndProvider>
  );
};

export default OpenTaskList;
