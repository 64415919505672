import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';

import Pending from './pages/Pending';
import Analytics from './pages/Analytics/Analytics';
import TeamSummary from './pages/TeamSummary';
import RestrictedKeywords from './pages/RestrictedKeywords';
import TaskDetails from './pages/TaskDetails';
import MyWorkspace from './pages/MyWorkspace';
import TeamWorkspace from './pages/TeamWorkspace';

const Writing = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact={false} path={`${url}/analytics`} component={Analytics} />

      <Route exact={false} path={`${url}/open`} component={Pending} />

      <Route
        exact={false}
        path={`${url}/teams/:podId/summary`}
        component={TeamSummary}
      />

      <Route
        exact={false}
        path={`${url}/workspace/my`}
        component={MyWorkspace}
      />

      <Route
        exact={false}
        path={`${url}/workspace/team`}
        component={TeamWorkspace}
      />

      {[
        'client_copy_writing_task_list',
        'client_keyword_research_task_list',
        'client_product_research_task_list',
      ].map((code) => (
        <Route
          exact={true}
          key={`task-details-page-${code}`}
          path={`${url}/copy-writing/:taskItemId`}
          render={() => <TaskDetails code={code} />}
        />
      ))}

      <Route
        exact={false}
        path={`${url}/restricted-keywords`}
        component={RestrictedKeywords}
      />
    </Switch>
  );
};

export default withRouter(Writing);
