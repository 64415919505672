import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Routes from '../routes/Routes';

import { Alerts, AlertPopup } from 'features/alerts';
import { getMeAsync } from 'features/auth/authSlice';
import { fetchMaintenanceSettings } from 'features/settings/settingsSlice';

function App({ history }) {
  const dispatch = useDispatch();
  const { maintenance } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getMeAsync());
    dispatch(fetchMaintenanceSettings());
  }, [dispatch]);

  useEffect(() => {
    if (maintenance) {
      history.push('/maintenance');
    }
  }, [maintenance]);

  return (
    <div className="App">
      <Helmet />
      <AlertPopup />

      <Alerts />

      <Routes />
    </div>
  );
}

export default withRouter(App);
