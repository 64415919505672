import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ClientsRoutes from './Clients.Routes';
import AuthRoutes from './Auth.Routes';
import AppRoutes from './App.Routes';
import BillingRoutes from './Billing.Routes';
import LeadsRoutes from './Leads.Routes';
import AdminRoutes from './Admin.Routes';
import AccountsRoutes from './Accounts.Routes';
import SalesRoutes from './Sales.Routes';
import ErrorsRoutes from './Errors.Routes';
import WritingRoutes from './Writing.Routes';
import DesignRoutes from './Design.Routes';
import LabsRoutes from './Labs.Routes';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/sign-in" />
      </Route>

      {AuthRoutes}
      {ClientsRoutes}
      {BillingRoutes}
      {AdminRoutes}
      {AccountsRoutes}
      {LeadsRoutes}
      {SalesRoutes}
      {WritingRoutes}
      {DesignRoutes}
      {AppRoutes}
      {LabsRoutes}
      {ErrorsRoutes}
    </Switch>
  );
};

export default Routes;
