import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchIcon } from '@heroicons/react/outline';
import { useParams, useRouteMatch } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';

import { fetchTeamDetails, setBoardView } from '../designSlice';

import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';
import TaskWorkspace from '../components/TaskWorkspace';

import classNames from 'utils/classNames';

const TeamWorkspace = () => {
  const { podId } = useParams();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const { team, boardView } = useSelector((state) => state.design);

  useEffect(() => {
    dispatch(fetchTeamDetails(podId));
  }, [podId]);

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const updateGroupBy = (value) => {
    updateParams({ ...params, groupBy: value });
  };

  const groups = [
    { label: 'Status', value: 'status' },
    { label: 'Priority', value: 'priority' },
    { label: 'Assignees', value: 'assignees' },
    { label: 'Due Date', value: 'due' },
    { label: 'Client', value: 'client' },
  ];

  return parseInt(team.podId) === parseInt(podId) ? (
    <div>
      <div className="sm:grid grid-cols-8 mb-8 mt-2">
        <div className="col-span-4 flex items-center space-x-20">
          <h1 className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
            My Workspace
          </h1>
          <div className="border-b space-x-12 pb-1.5 relative -mb-2">
            <ButtonLink
              classes={classNames(
                boardView === 'list' ? 'font-semibold add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('list'))}
            >
              List
            </ButtonLink>
            <ButtonLink
              classes={classNames(
                boardView === 'kanban' ? 'font-semibold  add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('kanban'))}
            >
              Board
            </ButtonLink>
          </div>
        </div>
        <div className="col-span-1">&nbsp;</div>
        <div className="col-span-3 flex items-center justify-end space-x-3">
          <div className="w-2/6">
            <InputPrepend
              name="search"
              defaultValue={params?.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Tasks'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
            />
          </div>
          <label className="text-sm text-gray-500">Group By</label>
          <select
            label="status"
            value={params.groupBy}
            className="rounded-xl w-2/6 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
            onChange={(e) => updateGroupBy(e.target.value)}
          >
            {groups.map((group, i) => (
              <option key={i} value={group.value}>
                {group.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <TaskWorkspace
        code={'client_design_task_list'}
        podId={podId}
        params={params}
      />
    </div>
  ) : (
    ''
  );
};

export default TeamWorkspace;
