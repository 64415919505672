import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    pods: [],
  },
  reducers: {
    setPods: (state, action) => {
      state.pods = action.payload;
    },
  },
});

export const { setPods } = organizationSlice.actions;

export const getPodsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get('/agency/organization/pods', {
      params: { pageSize: 10000 },
    });

    await dispatch(setPods(response.data.data.rows));
  } catch (error) {
    console.log(error.message);
  }
};

export default organizationSlice.reducer;
