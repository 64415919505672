import { useParams } from 'react-router-dom';
import RecordsGraph from './RecordsGraph';
import Statistics from './Statistics';

const UserAnalytics = ({}) => {
  const { assigneeId } = useParams();

  return (
    <div>
      <p className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
        User Performance
      </p>

      <RecordsGraph reference={'created'} params={{ assigneeId }} />

      <Statistics title="User Overview" params={{ assigneeId }} />
    </div>
  );
};

export default UserAnalytics;
