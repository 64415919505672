import { useSelector } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import TaskSummary from '../components/TaskSummary';

const TeamWorkspace = () => {
  const { url } = useRouteMatch();
  const { user } = useSelector((state) => state.auth);

  const tabs = [
    {
      name: 'Copy Writing',
      url: `${url}/copy-writing`,
      code: 'client_copy_writing_task_list',
      exact: true,
    },
    {
      name: 'Keyword Research',
      url: `${url}/keyword-research`,
      code: 'client_keyword_research_task_list',
      exact: true,
    },
    {
      name: 'Product Research',
      url: `${url}/product-research`,
      code: 'client_product_research_task_list',
      exact: true,
    },
  ];

  return (
    <div>
      <p className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
        My Team’s Workspace
      </p>

      <div>
        <div className="flex justify-between items-center border-b border-gray-200 mt-5">
          <nav className="-mb-px flex space-x-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.url}
                exact={tab.exact}
                className="text-gray-500 flex items-center whitespace-nowrap leading-1.2 py-2 border-b-2 text-base border-transparent"
                activeClassName="border-red-500 text-red-500 font-bold"
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>

        <div className="my-5">
          <Switch>
            <Route
              exact
              path={url}
              render={() => <Redirect to={`${url}/copy-writing`} />}
            />

            {tabs.map((tab) => (
              <Route
                key={tab.code}
                exact={false}
                path={tab.url}
                render={() => (
                  <TaskSummary
                    code={tab.code}
                    podId={user.memberId?.podId}
                    squadId={user.memberId?.squadId}
                  />
                )}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default TeamWorkspace;
