import axios from 'axios';
import moment from 'moment';
import { isArray, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';

import { fetchTaskItems, fetchUsers } from '../writingSlice';

import TabNav from 'components/TabNav';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import PriorityDropdown from 'features/tasks/components/PriorityDropdown';
import TaskManagerAvatar from 'features/tasks/components/TaskManagerAvatar';
import TaskDueDateDropdown from 'features/tasks/components/TaskDueDateDropdown';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import usePermissions from 'hooks/usePermissions';

const TaskSummary = ({ code, podId, squadId }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { userCan } = usePermissions();
  const { taskListStatuses, items, users } = useSelector(
    (state) => state.writing
  );

  const [statuses, setStatuses] = useState(
    taskListStatuses[code].map((status, i) => {
      return {
        name: status.name,
        value: status.name,
        current: i === 0,
        className: 'text-base',
      };
    })
  );

  const { params, updateParams } = useQueryParams({
    search: '',
    status: 'Open',
  });

  const taskPermissions = {
    canDeleteTask: userCan('writing.task.delete'),
    canAddEmail: userCan('writing.task.email'),
    canUseTimer: userCan('writing.task.timer'),
    canAddFiles: userCan('writing.task.files.add'),
    canAddDueDate: userCan('writing.task.due.add'),
    canUpdateName: userCan('writing.task.title.update'),
    canAddAssignee: userCan('writing.task.assignee.add'),
    canAddComments: userCan('writing.task.comments.add'),
    canRemoveFiles: userCan('writing.task.files.remove'),
    canUpdateStatus: userCan('writing.task.status.update'),
    canUpdateDueDate: userCan('writing.task.due.update'),
    canAddSubtasks: userCan('writing.task.subTasks.add'),
    canEditSubtasks: userCan('writing.task.subTasks.edit'),
    canDeleteSubtasks: userCan('writing.task.subTasks.delete'),
    canRemoveAssignee: userCan('writing.task.assignee.remove'),
    canAddPriority: userCan('writing.task.prioritization.add'),
    canAddDescription: userCan('writing.task.description.add'),
    canUpdatePriority: userCan('writing.task.prioritization.update'),
    canUpdateDescription: userCan('writing.task.description.update'),
  };

  useEffect(() => {
    updateParams({ ...params, status: 'Open' });
  }, []);

  useEffect(() => {
    let payload = {
      pageSize: 1000,
      sort: 'firstName:asc',
      permissionAccesses: [`writing.${code}.task.assignable`],
    };

    if (podId) {
      payload.groupPodId = podId;
    }

    if (squadId) {
      payload.groupSquadId = squadId;
    }

    dispatch(fetchUsers(payload));
  }, [code, podId]);

  useEffect(() => {
    let payload = {
      ...params,
      taskListCodes: [code],
      include: ['list.folder.space.client', 'subtasks', 'assignees'],
    };

    if (podId) {
      payload.assigneesGroupPodId = podId;
    }

    if (squadId) {
      payload.assigneesGroupSquadId = squadId;
    }

    dispatch(fetchTaskItems(payload));
  }, [code, params, podId]);

  const refreshList = () => {
    let payload = {
      ...params,
      taskListCodes: [code],
      include: ['list.folder.space.client', 'subtasks', 'assignees'],
    };

    if (podId) {
      payload.assigneesGroupPodId = podId;
    }

    if (squadId) {
      payload.assigneesGroupSquadId = squadId;
    }

    dispatch(fetchTaskItems(payload));
  };

  const onUpdateAssignee = async (taskItemId, user, action) => {
    axios({
      method: action === 'add' ? 'POST' : 'DELETE',
      url: `/agency/tasks/items/${taskItemId}/assignees/${user.userId}`,
    }).then(() => refreshList());
  };

  const onChangeDueDate = (taskItemId, dueDate) => {
    axios
      .put(`/agency/tasks/items/${taskItemId}`, {
        dueDate,
      })
      .then(() => refreshList());
  };

  const onChangePriority = (taskItemId, priority) => {
    axios
      .put(`/agency/tasks/items/${taskItemId}`, {
        priority: priority ? priority.status : null,
      })
      .then(() => refreshList());
  };

  return (
    <div className="mt-5">
      <TabNav
        tabs={statuses}
        setTabs={setStatuses}
        onClick={(tab) => updateParams({ status: tab.value })}
        theme="tabbed"
      />

      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500">
                Date Assigned
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-left">
                Client Name
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Assignees
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Brand Strategist
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Account Specialist
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Due Date
              </td>
              <td className="font-bold text-base text-grayscale-600 leading-1.5 sticky whitespace-nowrap py-5 px-2.5 bg-grayscale-200 border-b border-grayscale-500 text-center">
                Priority
              </td>
            </tr>
          </thead>

          {isArray(items) && (
            <tbody>
              {items.length ? (
                items.map((item) => {
                  return (
                    <tr
                      onClick={() => history.push(`${url}/${item.taskItemId}`)}
                      key={`team-task-item-${item.taskItemId}`}
                      className="cursor-pointer"
                    >
                      <td className="text-grayscale-800 leading-1.5 px-2.5 py-5">
                        {moment(
                          item.assignees.some(
                            (a) => a.TaskItemAssignee?.assignedAt
                          )
                            ? sortBy(
                                item.assignees,
                                'TaskItemAssignee.assignedAt'
                              )[0].TaskItemAssignee.assignedAt
                            : item.createdAt
                        ).format('MMMM Do, YYYY')}
                      </td>
                      <td className="text-grayscale-800 leading-1.5 px-2.5 py-5">
                        {item.list.folder.space.client.client}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <UsersPopoverMultiple
                            users={users.rows}
                            allowAdd={!!taskPermissions.canAddAssignee}
                            allowRemove={!!taskPermissions.canRemoveAssignee}
                            position="left-1/2 transform -translate-x-1/2"
                            onChange={(user, action) =>
                              onUpdateAssignee(item.taskItemId, user, action)
                            }
                            selectedIds={item.assignees.map(
                              (user) => user.userId
                            )}
                          />
                        </div>
                      </td>
                      <td className="px-2.5 py-5">
                        {item.list?.folder?.space?.client
                          ?.seniorAccountManager && (
                          <div className="flex justify-center">
                            <TaskManagerAvatar
                              user={
                                item.list.folder.space.client
                                  .seniorAccountManager.user
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-2.5 py-5">
                        {item.list?.folder?.space?.client?.accountManager && (
                          <div className="flex justify-center">
                            <TaskManagerAvatar
                              user={
                                item.list.folder.space.client.accountManager
                                  .user
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-2.5 py-5">
                        <div className="flex justify-center">
                          <TaskDueDateDropdown
                            value={item.dueDate}
                            configurable={!!taskPermissions.canAddDueDate}
                            editable={!!taskPermissions.canUpdateDueDate}
                            setValue={(value) =>
                              onChangeDueDate(item.taskItemId, value)
                            }
                          />
                        </div>
                      </td>

                      <td className="px-2.5 py-5">
                        <div className="flex justify-center">
                          <PriorityDropdown
                            value={item.priority}
                            setValue={(value) =>
                              onChangePriority(item.taskItemId, value)
                            }
                            configurable={!!taskPermissions.canAddPriority}
                            editable={!!taskPermissions.canUpdatePriority}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={7}
                    className="text-grayscale-800 leading-1.5 px-2.5 py-5 text-center"
                  >
                    No tickets under this status
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={refreshList}
                assigneeOptions={users.rows}
                notifyAssignee={true}
                permissions={taskPermissions}
                serviceAsinData={
                  code === 'client_copy_writing_task_list'
                    ? {
                        name: 'service-asin',
                        type: 'service-asin',
                        title: 'Service ASIN',
                        checklists: [
                          {
                            name: 'Content work needed',
                            description: 'Content work needed',
                            items: [
                              {
                                value: 'Listing Copy',
                              },
                              {
                                value: 'A+ Copy',
                              },
                            ],
                          },
                        ],
                      }
                    : {}
                }
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default TaskSummary;
