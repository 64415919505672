import { debounce } from 'lodash';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/outline';

import OpenTasks from '../components/OpenTasks';
import InputPrepend from 'components/Forms/InputPrepend';

import useBodyClass from 'hooks/useBodyClass';
import useQueryParams from 'hooks/useQueryParams';
import usePermissions from 'hooks/usePermissions';

const Pending = () => {
  const { url } = useRouteMatch();
  const { userCan } = usePermissions();

  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const groups = [
    { label: 'Status', value: 'status' },
    { label: 'Priority', value: 'priority' },
    { label: 'Assignees', value: 'assignees' },
    { label: 'Due Date', value: 'due' },
    { label: 'Client', value: 'client' },
  ];

  useBodyClass(['client-profile', 'no-sidebar']);

  const tabs = [
    {
      name: 'Copy Writing',
      url: `${url}/copy-writing`,
      code: 'client_copy_writing_task_list',
      permissionCode: 'copy_writing',
      exact: true,
      visible: userCan('writing.pending.copy_writing.view'),
    },
    {
      name: 'Keyword Research',
      url: `${url}/keyword-research`,
      code: 'client_keyword_research_task_list',
      exact: true,
      permissionCode: 'keyword_research',
      visible: userCan('writing.pending.keyword_research.view'),
    },
    {
      name: 'Product Research',
      url: `${url}/product-research`,
      code: 'client_product_research_task_list',
      exact: true,
      permissionCode: 'product_research',
      visible: userCan('writing.pending.product_research.view'),
    },
  ];

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="mr-8">
      <div className="sm:grid grid-cols-8">
        <p className="col-span-4 text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
          Pending Tasks
        </p>

        <div className="col-span-4 flex items-center justify-end space-x-3">
          <div className="w-2/6">
            <InputPrepend
              name="search"
              defaultValue={params?.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Tasks'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
            />
          </div>
          <label className="text-sm text-gray-500">Group By</label>
          <select
            label="status"
            value={params.groupBy}
            className="rounded-xl w-2/6 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
            onChange={(e) =>
              updateParams({ ...params, groupBy: e.target.value })
            }
          >
            {groups.map((group, i) => (
              <option key={i} value={group.value}>
                {group.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-between items-center border-b border-gray-200 mt-5">
        <nav className="-mb-px flex space-x-6" aria-label="Tabs">
          {tabs
            .filter((tab) => tab.visible)
            .map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.url}
                exact={tab.exact}
                className="text-gray-500 flex items-center whitespace-nowrap leading-1.2 py-2 border-b-2 text-base border-transparent"
                activeClassName="border-red-500 text-red-500 font-bold"
              >
                {tab.name}
              </NavLink>
            ))}
        </nav>
      </div>

      <div className="my-5">
        <Switch>
          <Route
            exact
            path={url}
            render={() => {
              let redirect = `${url}/copy-writing`;

              if (
                !userCan('writing.pending.copy_writing.view') &&
                redirect === `${url}/copy-writing`
              ) {
                redirect = `${url}/keyword-research`;
              }

              if (
                !userCan('writing.pending.keyword_research.view') &&
                redirect === `${url}/keyword-research`
              ) {
                redirect = `${url}/product-research`;
              }

              if (
                !userCan('writing.pending.product_research.view') &&
                redirect === `${url}/product-research`
              ) {
                redirect = `/permission-denied`;
              }

              return <Redirect to={redirect} />;
            }}
          />

          {tabs.map((tab) => (
            <Route
              key={tab.code}
              exact={false}
              path={tab.url}
              render={() => (
                <OpenTasks
                  code={tab.code}
                  permissionCode={tab.permissionCode}
                  params={params}
                />
              )}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default Pending;
