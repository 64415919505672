import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Pending from './pages/Pending';
import TeamSummary from './pages/TeamSummary';
import TeamWorkspace from './pages/TeamWorkspace';
import Analytics from './pages/Analytics/Analytics';

const Design = () => {
  const { url } = useRouteMatch();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="">
        <Switch>
          <Route
            exact={false}
            path={`${url}/analytics`}
            component={Analytics}
          />

          <Route exact={false} path={`${url}/open`} component={Pending} />

          <Route
            exact={false}
            path={`${url}/teams/:podId/summary`}
            component={TeamSummary}
          />

          <Route
            exact={false}
            path={`${url}/teams/:podId/workspace`}
            component={TeamWorkspace}
          />
        </Switch>
      </div>
    </DndProvider>
  );
};

export default withRouter(Design);
