import moment from 'moment-timezone';

import usFlag from 'assets/flags/us.png';
import mxFlag from 'assets/flags/mx.png';
import caFlag from 'assets/flags/ca.png';
import brFlag from 'assets/flags/br.png';
import { isArray, isObject } from 'lodash';

export const percentageFormatter = (number) =>
  parseFloat(number).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });

export const floatFormatter = (number) => parseFloat(number).toFixed(2);

export const numberFormatter = (number) =>
  number ? number.toLocaleString() : 0;

export const currencyFormatter = (number, currency_code = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency_code,
  });

  return formatter.format(number);
};

export const strDashToSpace = (string) => {
  return string.replace(/-/g, ' ');
};

export const strUnderscoreToSpace = (string) => {
  return string.replace(/_/g, ' ');
};

export const strSpaceToDash = (string) => {
  return string.replace(/ /g, '-');
};

export const dateFormatterUTC = (date, stringFormat = 'DD MMM YYYY') => {
  return moment.utc(date).format(stringFormat);
};

export const ago = (date) => {
  moment.tz.setDefault('America/Toronto');

  const iscurrentDate = moment(date).isSame(new Date(), 'day');

  return iscurrentDate ? moment(date).fromNow() : moment(date).format('lll');
};

export const agoUTC = (date) => {
  return moment.utc(date).fromNow();
};

export const simpleFromNow = (date) => {
  moment.locale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '1s',
      ss: '%ss',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1',
      yy: '%dY',
    },
  });

  return moment(date).fromNow();
};

export const dateFormatter = (date, stringFormat = 'DD MMM YYYY') => {
  moment.tz.setDefault('America/Toronto');

  return moment(date).format(stringFormat);
};

export const countryCodeToFlag = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return usFlag;
    case 'CA':
      return caFlag;
    case 'MX':
      return mxFlag;
    case 'BR':
      return brFlag;
    default:
      break;
  }
};

export const decimalFormatter = (number) => parseFloat(number).toFixed(2);

export const joiAlertErrorsStringify = (error) => {
  return error && error.response.data.errors
    ? Object.keys(error.response.data.errors)
        .map((key) => {
          return `- ${error.response.data.errors[key]}`;
        })
        .join('\n')
    : '';
};

export const alertErrorsStringify = (errors) => {
  return errors
    ? Object.keys(errors)
        .map((key) => {
          if (isObject(errors[key])) {
            return alertErrorsStringify(errors[key]);
          } else {
            return errors[key];
          }
        })
        .join('\n')
    : '';
};

export const objectStringify = (data) => {
  return data
    ? Object.keys(data)
        .map((key) => {
          if (isObject(data[key])) {
            return objectStringify(data[key]);
          } else {
            return data[key];
          }
        })
        .join('\n')
    : '';
};

export const getNameInitials = (firstname, lastname) => {
  return `${firstname ? firstname.charAt(0) : ''}${
    lastname ? lastname.charAt(0) : ''
  }`;
};

export const nameFormatter = (user) => {
  return user
    ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`
    : '';
};

export const calendarTime = (date) => {
  let fromNow = moment.utc(date).endOf('day').fromNow();

  let output = moment.utc(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: function () {
      return `[${fromNow}]`;
    },
    sameElse: 'DD MMM YYYY',
  });

  return output;
};

export const joinObject = (value, key) => {
  if (!value) return '';
  return value.map((el) => el[key]).join(', ');
};

export const parseArray = (param) => {
  if (isArray(param)) {
    return param;
  }

  if (param && param !== '') {
    return param.split(',');
  }

  return [];
};

/**
 * return null if string === ''
 * @param {string} value
 * @returns value or null
 */
export const ifEmptyThenNull = (value) => {
  return value === '' ? null : value;
};

/**
 * return  '' if value is null
 * @param {string} value
 * @param {string} empty or string
 * @returns value or null
 */
export const ifNullThenEmpty = (value, returnValue = '') => {
  return value ?? returnValue;
};

export const ifNullThenEmptyArray = (value, returnValue = []) => {
  return value ?? returnValue;
};
