const PRIORITIES = [
  {
    value: 'high',
    text: 'High',
    className: 'text-error-dark bg-error-light',
  },
  {
    value: 'normal',
    text: 'Mid',
    className: 'text-warning-dark bg-warning-light',
  },
  {
    value: 'low',
    text: 'Low',
    className: 'text-secondary-dark bg-secondary-light',
  },
  {
    value: 'none',
    text: 'None',
    className: 'text-grayscale-800 bg-grayscale-500',
  },
];

const PENDING_STATUS = {
  type: 'open',
  name: 'Pending',
  color: '#002F5D',
  bgColor: '#D3E4F5',
};

const OPEN_STATUS = {
  type: 'open',
  name: 'Open',
  color: '#666666',
  bgColor: '#ECECEC',
};

const TODO_STATUS = {
  type: 'open',
  name: 'To Do',
  color: '#002f5d',
  bgColor: '#d3e4f5',
};

const INCOMPLETE_STATUS = {
  type: 'open',
  name: 'Incomplete',
  color: '#D85B5B',
  bgColor: '#FFE6E6',
};

const ON_HOLD_OR_CANCEL_STATUS = {
  type: 'open',
  name: 'On Hold or Cancel',
  color: '#D48236',
  bgColor: '#FFD2B9',
};

const IN_PROGRESS_STATUS = {
  type: 'open',
  name: 'In Progress',
  color: '#BFA654',
  bgColor: '#FFEFD0',
};

const FOR_INITIAL_EDITING_STATUS = {
  type: 'open',
  name: 'For Initial Editing',
  color: '#1879D8',
  bgColor: '#f2e1ff',
};

const FOR_FINAL_EDITING_STATUS = {
  type: 'open',
  name: 'For Final Editing',
  color: '#ae63d1',
  bgColor: '#f2e1ff',
};

const SUBMITTED_STATUS = {
  type: 'open',
  name: 'Submitted',
  color: '#13e9b0',
  bgColor: '#cfffdd',
};

const REVISION_STATUS = {
  type: 'open',
  name: 'Revision',
  color: '#c30000',
  bgColor: '#ffafaf',
};

const DONE_STATUS = {
  type: 'closed',
  name: 'Done',
  color: '#00966D',
  bgColor: '#CFFFDD',
};

const COMPLETE_STATUS = {
  type: 'closed',
  name: 'Complete',
  color: '#00966d',
  bgColor: '#cfffdd',
};

const CLIENT_OPERATION_TASK_LIST_STATUSES = [
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  DONE_STATUS,
];

const CLIENT_COPY_WRITING_TASK_LIST_STATUSES = [
  OPEN_STATUS,
  INCOMPLETE_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  TODO_STATUS,
  IN_PROGRESS_STATUS,
  FOR_INITIAL_EDITING_STATUS,
  FOR_FINAL_EDITING_STATUS,
  SUBMITTED_STATUS,
  REVISION_STATUS,
  COMPLETE_STATUS,
];

const CLIENT_KEYWORD_RESEARCH_TASK_LIST_STATUSES = [
  OPEN_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  INCOMPLETE_STATUS,
  IN_PROGRESS_STATUS,
  COMPLETE_STATUS,
];

const CLIENT_PRODUCT_RESEARCH_TASK_LIST_STATUSES = [
  OPEN_STATUS,
  INCOMPLETE_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  TODO_STATUS,
  IN_PROGRESS_STATUS,
  FOR_INITIAL_EDITING_STATUS,
  REVISION_STATUS,
  COMPLETE_STATUS,
];

const CLIENT_DESIGN_TASK_LIST_STATUSES = [
  OPEN_STATUS,
  INCOMPLETE_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  TODO_STATUS,
  IN_PROGRESS_STATUS,
  FOR_INITIAL_EDITING_STATUS,
  FOR_FINAL_EDITING_STATUS,
  SUBMITTED_STATUS,
  REVISION_STATUS,
  COMPLETE_STATUS,
];

const CLIENT_PPC_TASK_LIST_STATUSES = [
  OPEN_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  INCOMPLETE_STATUS,
  IN_PROGRESS_STATUS,
  COMPLETE_STATUS,
];

const CLIENT_ADMIN_TASK_LIST_STATUSES = [
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  DONE_STATUS,
];

const CLIENT_BILLING_TASK_LIST_STATUSES = [
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  DONE_STATUS,
];

const CLIENT_SALES_TASK_LIST_STATUSES = [
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  DONE_STATUS,
];

const CLIENT_LEAD_GENERATION_TASK_LIST_STATUSES = [
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  DONE_STATUS,
];

const CLIENT_ONBOARDING_TASK_LIST_STATUSES = [PENDING_STATUS, DONE_STATUS];

const TASK_LIST_STATUSES = {
  CLIENT_PPC_TASK_LIST_STATUSES,
  CLIENT_DESIGN_TASK_LIST_STATUSES,
  CLIENT_OPERATION_TASK_LIST_STATUSES,
  CLIENT_COPY_WRITING_TASK_LIST_STATUSES,
  CLIENT_KEYWORD_RESEARCH_TASK_LIST_STATUSES,
  CLIENT_PRODUCT_RESEARCH_TASK_LIST_STATUSES,
  CLIENT_ADMIN_TASK_LIST_STATUSES,
  CLIENT_BILLING_TASK_LIST_STATUSES,
  CLIENT_SALES_TASK_LIST_STATUSES,
  CLIENT_LEAD_GENERATION_TASK_LIST_STATUSES,
  CLIENT_ONBOARDING_TASK_LIST_STATUSES,
};

const STATUS_LIST = [
  PENDING_STATUS,
  OPEN_STATUS,
  TODO_STATUS,
  INCOMPLETE_STATUS,
  ON_HOLD_OR_CANCEL_STATUS,
  IN_PROGRESS_STATUS,
  FOR_INITIAL_EDITING_STATUS,
  FOR_FINAL_EDITING_STATUS,
  SUBMITTED_STATUS,
  REVISION_STATUS,
  DONE_STATUS,
  COMPLETE_STATUS,
];

module.exports = {
  PRIORITIES,
  TASK_LIST_STATUSES,
  STATUS_LIST,
  PENDING_STATUS,
  DONE_STATUS,
};
