import { debounce } from 'lodash';
import { SearchIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  NavLink,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import { setBoardView } from '../writingSlice';
import useQueryParams from 'hooks/useQueryParams';

import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';

import TaskWorkspace from '../components/TaskWorkspace';

import classNames from 'utils/classNames';

const groups = [
  { label: 'Status', value: 'status' },
  { label: 'Priority', value: 'priority' },
  { label: 'Assignees', value: 'assignees' },
  { label: 'Due Date', value: 'due' },
  { label: 'Client', value: 'client' },
];

const MyWorkspace = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { boardView } = useSelector((state) => state.writing);
  const { user } = useSelector((state) => state.auth);

  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const updateGroupBy = (value) => {
    updateParams({ ...params, groupBy: value });
  };

  const tabs = [
    {
      name: 'Copy Writing',
      url: `${url}/copy-writing`,
      code: 'client_copy_writing_task_list',
      exact: true,
      permissionCode: 'copy_writing',
    },
    {
      name: 'Keyword Research',
      url: `${url}/keyword-research`,
      code: 'client_keyword_research_task_list',
      exact: true,
      permissionCode: 'keyword_research',
    },
    {
      name: 'Product Research',
      url: `${url}/product-research`,
      code: 'client_product_research_task_list',
      exact: true,
      permissionCode: 'product_research',
    },
  ];

  return (
    <div>
      <div className="sm:grid grid-cols-8 mb-8 mt-2">
        <div className="col-span-4 flex items-center space-x-20">
          <h1 className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
            My Workspace
          </h1>
          <div className="border-b space-x-12 pb-1.5 relative -mb-2">
            <ButtonLink
              classes={classNames(
                boardView === 'list' ? 'font-semibold add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('list'))}
            >
              List
            </ButtonLink>
            <ButtonLink
              classes={classNames(
                boardView === 'kanban' ? 'font-semibold  add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('kanban'))}
            >
              Board
            </ButtonLink>
          </div>
        </div>
        <div className="col-span-1">&nbsp;</div>
        <div className="col-span-3 flex items-center justify-end space-x-3">
          <div className="w-2/6">
            <InputPrepend
              name="search"
              defaultValue={params?.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Tasks'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
            />
          </div>
          <label className="text-sm text-gray-500">Group By</label>
          <select
            label="status"
            value={params.groupBy}
            className="rounded-xl w-2/6 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
            onChange={(e) => updateGroupBy(e.target.value)}
          >
            {groups.map((group, i) => (
              <option key={i} value={group.value}>
                {group.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center border-b border-gray-200 mt-5">
          <nav className="-mb-px flex space-x-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.url}
                exact={tab.exact}
                className="text-gray-500 flex items-center whitespace-nowrap leading-1.2 py-2 border-b-2 text-base border-transparent"
                activeClassName="border-red-500 text-red-500 font-bold"
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>

        <div className="my-5">
          <Switch>
            <Route
              exact
              path={url}
              render={() => <Redirect to={`${url}/copy-writing`} />}
            />

            {tabs.map((tab) => (
              <Route
                key={tab.code}
                exact={false}
                path={tab.url}
                render={() => (
                  <TaskWorkspace
                    code={tab.code}
                    podId={user.memberId?.podId}
                    squadId={user.memberId?.squadId}
                    params={params}
                  />
                )}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MyWorkspace;
