import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import Design from 'features/design/Design';

const DesignRoutes = [
  <PrivateRoute
    path="/design"
    component={Design}
    layout={AgencyLayout}
    access={['agency']}
  />,
];
export default DesignRoutes;
