import { useSelector } from 'react-redux';
import MemberList from './MemberList';
import RecordsGraph from './RecordsGraph';
import Statistics from './Statistics';
import TeamList from './TeamList';

const OverallAnalytics = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <p className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
        Writing Team Performance
      </p>

      <RecordsGraph reference={'created'} params={{}} />

      <Statistics params={{}} />

      {!user.memberId?.podId ? (
        <TeamList />
      ) : user.memberId?.cellId ? (
        ''
      ) : (
        <MemberList teamId={user.memberId?.podId} />
      )}
    </div>
  );
};

export default OverallAnalytics;
