import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { XAxis, Bar, ResponsiveContainer, BarChart, Tooltip } from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

import { percentageFormatter } from 'utils/formatters';

const RecordsGraph = ({ reference, params = {} }) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        `/agency/tasks/items/status/Revision/${reference}/records`,
        {
          params: {
            ...params,
            scopes: ['parentTasks'],
            attributes: ['count'],
            taskListCodes: ['client_design_task_list'],
            startDate: moment()
              .startOf('month')
              .subtract(11, 'months')
              .format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
          },
        }
      );

      if (isSubscribed) {
        setRecords(
          response.data.data.map((record) => {
            return {
              revisionTasks: record.revision.count,
              designTasks: record.mainTask.count,
              totalTasks: record.overall.count,
              month: moment(record.startDate).format('MMM'),
            };
          })
        );
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [reference, params]);

  return (
    <div className="grid grid-cols-2 mt-8 gap-8">
      <div className="rounded-3xl bg-white bs-shadow py-6">
        <div className="grid grid-cols-8 gap-4 border-b border-grayscale-500 px-4 pb-4">
          <div className="col-span-3">
            <p className="text-xl font-inter font-bold tracking-3/4 leading-1.2 text-grayscale-900">
              Overall Tasks
            </p>
          </div>
          <div className="col-span-5">
            <div className="grid grid-cols-2">
              <div className="flex">
                <div
                  className="mr-2 bg-secondary-light"
                  style={{ height: '20px', width: '30px' }}
                ></div>
                <p className="text-11 text-grayscale-800 leading-1.5">
                  TOTAL TASKS
                </p>
              </div>
              <div>
                <div className="flex">
                  <div
                    className="mr-2 bg-success"
                    style={{ height: '20px', width: '30px' }}
                  ></div>
                  <p className="text-11 text-grayscale-800 leading-1.5">
                    DESIGN TASKS
                  </p>
                </div>
                <div className="flex mt-2">
                  <div
                    className="mr-2 bg-error-dark"
                    style={{ height: '20px', width: '30px' }}
                  ></div>
                  <p className="text-11 text-grayscale-800 leading-1.5">
                    REVISION TASKS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-6">
          <div className="h-44 mx-auto overflow-hidden">
            <ResponsiveContainer className="" height="100%">
              <BarChart data={records}>
                <XAxis
                  dataKey="month"
                  tickSize={0}
                  dy={10}
                  tickCount={5}
                  axisLine={{ stroke: '#cbd5e1' }}
                  style={{
                    fontWeight: '400',
                    fontSize: '11px',
                    color: '#202020',
                  }}
                />

                <Tooltip
                  content={
                    <CustomTooltip containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl w-48" />
                  }
                />

                <Bar
                  dataKey="totalTasks"
                  fill="#1879D8"
                  isAnimationActive={false}
                  barSize={13}
                  name="Total Tasks"
                />

                <Bar
                  dataKey="designTasks"
                  fill="#00BA88"
                  isAnimationActive={false}
                  barSize={13}
                  name="Design Tasks"
                />

                <Bar
                  dataKey="revisionTasks"
                  fill="#C30000"
                  isAnimationActive={false}
                  barSize={13}
                  name="Revision Tasks"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="rounded-3xl bg-white bs-shadow py-6">
        <div className="flex justify-between border-b border-grayscale-500 px-4 pb-4">
          <p className="text-xl font-inter font-bold tracking-3/4 leading-1.2 text-grayscale-900">
            Revision to <br /> Total Task Ratio %
          </p>

          <div className="flex mr-8">
            <div
              className="mr-2 bg-secondary-light"
              style={{ height: '20px', width: '30px' }}
            ></div>
            <p className="text-11 text-grayscale-800 leading-1.5">Data</p>
          </div>
        </div>

        <div className="px-4 py-6">
          <div className="h-44 mx-auto overflow-hidden">
            <ResponsiveContainer className="" height="100%">
              <BarChart
                data={records.map((record) => {
                  return {
                    ...record,
                    ratio: record.revisionTasks / record.totalTasks || 0,
                  };
                })}
              >
                <XAxis
                  dataKey="month"
                  tickSize={0}
                  dy={10}
                  tickCount={5}
                  axisLine={{ stroke: '#cbd5e1' }}
                  style={{
                    fontWeight: '400',
                    fontSize: '11px',
                    color: '#202020',
                  }}
                />

                <Tooltip
                  content={
                    <CustomTooltip containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl w-48" />
                  }
                />

                <Bar
                  dataKey="ratio"
                  fill="#1879D8"
                  isAnimationActive={false}
                  barSize={13}
                  formatter={(value) => percentageFormatter(value)}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordsGraph;
