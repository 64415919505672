import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import Writing from 'features/writing/Writing';

const WritingRoutes = [
  <PrivateRoute
    path="/writing"
    component={Writing}
    layout={AgencyLayout}
    access={['agency']}
  />,
];
export default WritingRoutes;
