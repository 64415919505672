import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OverallAnalytics from './components/OverallAnalytics';
import TeamAnalytics from './components/TeamAnalytics';
import UserAnalytics from './components/UserAnalytics';

const Analytics = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={url} component={OverallAnalytics} />

      <Route
        exact={true}
        path={`${url}/teams/:teamId`}
        component={TeamAnalytics}
      />

      <Route
        exact={true}
        path={`${url}/assignee/:assigneeId`}
        component={UserAnalytics}
      />
    </Switch>
  );
};

export default Analytics;
