import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import QuoteBody from './components/QuoteBody';
import QuoteHeader from './components/QuoteHeader';
import permissionDeniedImg from 'assets/images/permission_denied.svg';

const Show = () => {
  const { identifier } = useParams();
  const [quote, setQuote] = useState(null);

  const load = async () => {
    await axios
      .get(`/agency/sales/quotes/${identifier}`)
      .then((response) => setQuote(response.data.data));
  };

  useEffect(() => load(), [identifier]);

  useEffect(() => {
    if (quote && quote.status !== 'accepted' && !quote.isExpired) {
      console.log('hh');
      markAsSeen();
    }
  }, [quote]);

  const markAsSeen = async () => {
    await axios.patch(`/agency/sales/quotes/${quote?.quoteId}/seen`);
  };

  return (
    <div className="h-screen print:h-auto bg-white flex overflow-hidden print:overflow-visible agency-layout">
      <div className="content-area flex-1 flex flex-col overflow-hidden">
        <QuoteHeader quote={quote} setQuote={setQuote} type="show" />
        {quote?.isExpired ? (
          <div className="text-center flex items-center justify-center">
            <div className="relative mt-12 flex flex-col text-center">
              <h1 className="text-3xl text-gray-700 font-medium">
                Document Expired
              </h1>
              <h3 className="text-base text-gray-600 font-light">
                Please ask for assistance
              </h3>
              <span className="inline-block w-72 h-72 mt-8">
                <img
                  className="w-full"
                  alt="permission-denied"
                  src={permissionDeniedImg}
                />
              </span>
            </div>
          </div>
        ) : (
          <QuoteBody quote={quote} />
        )}
      </div>
    </div>
  );
};
export default Show;
