import { debounce } from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/outline';

import OpenTasks from '../components/OpenTasks';
import InputPrepend from 'components/Forms/InputPrepend';

import useBodyClass from 'hooks/useBodyClass';
import useQueryParams from 'hooks/useQueryParams';

const Pending = () => {
  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const groups = [
    { label: 'Status', value: 'status' },
    { label: 'Priority', value: 'priority' },
    { label: 'Assignees', value: 'assignees' },
    { label: 'Due Date', value: 'due' },
    { label: 'Client', value: 'client' },
  ];

  useBodyClass(['client-profile', 'no-sidebar']);

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="mr-8">
      <div className="sm:grid grid-cols-8">
        <p className="col-span-4 text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
          Pending Tasks
        </p>

        <div className="col-span-4 flex items-center justify-end space-x-3">
          <div className="w-2/6">
            <InputPrepend
              name="search"
              defaultValue={params?.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Tasks'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
            />
          </div>
          <label className="text-sm text-gray-500">Group By</label>
          <select
            label="status"
            value={params.groupBy}
            className="rounded-xl w-2/6 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
            onChange={(e) =>
              updateParams({ ...params, groupBy: e.target.value })
            }
          >
            {groups.map((group, i) => (
              <option key={i} value={group.value}>
                {group.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-8">
        <OpenTasks code={'client_design_task_list'} params={params} />
      </div>
    </div>
  );
};

export default Pending;
